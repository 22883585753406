import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode, ConfirmDialogComponent } from "@impacgroup/angular-baselib";
import { PostcodeAreasService } from "./postcodeareas.service";
import { TranslateService } from "@ngx-translate/core";
import { PostcodeArea } from "../../api-models/PostcodeArea";
import { BsModalService } from "ngx-bootstrap/modal";
import { OAuthUserListOutputDTO, PostcodeAreaUser, PostcodeAreaUserCreateInputDTO, PostcodeAreaUserListOutputDTO } from "../../api-models/PostcodeAreaUser";
import { forkJoin, Observable } from "rxjs";

@Component({
    selector: "app-postcodeareadetail",
    templateUrl: "./postcodeareadetail.component.html",
    styleUrls: ["postcodeareadetail.component.scss"]
})
export class PostcodeAreaDetailComponent extends BaseComponent implements OnInit {
    private postcodeAreaId: string = null;
    public postcodeArea: PostcodeArea = null;

    public users: PostcodeAreaUser[] = [];

    public assignedUsers: PostcodeAreaUserListOutputDTO[] = [];
    public availableUsers: OAuthUserListOutputDTO[] = [];

    constructor(
        private route: ActivatedRoute,
        private postcodeAreasService: PostcodeAreasService,
        private router: Router,
        private alertService: AlertService,
        private translateService: TranslateService,
        private _location: Location,
        private modalService: BsModalService
    ) {
        super();

        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.postcodeAreaId = params.id;
                if (this.postcodeAreaId) {
                    this.initEditMode();
                } else {
                    this.initAddMode();
                }
            })
        );
    }

    ngOnInit(): void {}

    initAddMode() {
        this.MODE = BaseComponentMode.ADD;
        this.postcodeArea = new PostcodeArea();
    }

    initEditMode() {
        this.MODE = BaseComponentMode.EDIT;

        this.subscriptions.push(
            this.postcodeAreasService.postcodeAreaDetail(this.postcodeAreaId).subscribe(
                (result) => {
                    this.postcodeArea = result;
                },
                (err) => {
                    this.router.navigate(["../"], { relativeTo: this.route });
                }
            )
        );

        this.loadUsers();
    }

    save() {
        if (this.MODE === BaseComponentMode.ADD) {
            this.subscriptions.push(
                this.postcodeAreasService.addPostcodeArea(this.postcodeArea).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("postcodeareas.detail.add.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }

        if (this.MODE === BaseComponentMode.EDIT) {
            this.subscriptions.push(
                this.postcodeAreasService.updatePostcodeArea(this.postcodeArea).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("postcodeareas.detail.edit.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }
    }

    deletePostcodeAreaUser(user) {
        const initialState = {
            title: this.translateService.instant("postcodeareas.detail.assignedUsers.delete.title"),
            message: this.translateService.instant("postcodeareas.detail.assignedUsers.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            this.subscriptions.push(
                this.postcodeAreasService.deletePostcodeUser(this.postcodeArea._id, user._id).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("postcodeareas.detail.assignedUsers.delete.success"));
                    this.loadUsers();
                })
            );
        };
    }

    assignUserToPostcodeArea(user) {
        const userToAdd = new PostcodeAreaUserCreateInputDTO();
        userToAdd.user = user._id;

        this.subscriptions.push(
            this.postcodeAreasService.addPostcodeUser(this.postcodeArea._id, userToAdd).subscribe((result) => {
                this.alertService.addSuccess(this.translateService.instant("postcodeareas.detail.assignedUsers.assign.success"));

                this.loadUsers();
            })
        );
    }

    back() {
        this._location.back();
    }

    private loadUsers() {
        let assignedUsersSub = this.postcodeAreasService.getAssignedPostcodeUsers(this.postcodeAreaId);
        let availableUsersSub = this.postcodeAreasService.getAvailablePostcodeUsers(this.postcodeAreaId);

        this.subscriptions.push(
            forkJoin([assignedUsersSub, availableUsersSub]).subscribe((results) => {
                this.assignedUsers = results[0].filter((el) => !!el.user).sort((a, b) => a.user.lastname.localeCompare(b.user.lastname));
                const assignedUsers = this.assignedUsers.map((dtoUser) => {
                    return PostcodeAreaUser.fromDTO(dtoUser);
                });

                this.availableUsers = results[1].sort((a, b) => a.lastname.localeCompare(b.lastname));
                const availableUsers = this.availableUsers.map((availableUser) => {
                    return PostcodeAreaUser.fromOAuthDTO(availableUser);
                });

                this.users = [...assignedUsers, ...availableUsers];
            })
        );
    }

    changeUserCheckbox(event, user) {
        const newCheckboxState = event.target.checked;

        // Prevent toggling checkbox until the modal is answered (or not)
        event.target.checked = !newCheckboxState;
        user.checked = !newCheckboxState;

        if (newCheckboxState === true) {
            this.assignUserToPostcodeArea(user);
        } else {
            this.deletePostcodeAreaUser(user);
        }
    }
}
