/* eslint-disable max-classes-per-file */
import { Expose, Transform, Type } from "class-transformer";
import * as moment from "moment";

class UserDTO {
    @Expose()
    @Transform(({ key, obj }) => (obj[key] !== undefined && obj[key] !== null ? String(obj[key]) : undefined))
    _id?: string;

    @Expose()
    firstname?: string;

    @Expose()
    lastname?: string;

    @Expose()
    email?: string;
}

// --------------------- used by list method --------------------- //
export class VisitReportListResponseDTO {
    @Expose()
    dateByMode?: string;

    @Expose()
    @Type(() => UserDTO)
    user?: UserDTO;

    @Expose()
    checklistCount?: number;

    public getFormattedUser(): string {
        const user = this.user;

        return `${user.firstname ? `${user.firstname} ` : ""}${user.lastname ? `${user.lastname} ` : ""}${user.firstname || user.lastname ? "\r\n" : ""}${user.email ? `${user.email} ` : ""}`;
    }

    public getFormattedDate(mode: DateParseMode): string {
        if (mode === "day") {
            return moment(this.dateByMode, "YYYY-MM-DD").format("DD.MM.YYYY");
        }
        if (mode === "week") {
            return `KW ${moment(this.dateByMode, "YYYY-WW").format("WW / YYYY")}`;
        }
        if (mode === "month") {
            return moment(this.dateByMode, "YYYY-MM").format("MMMM / YYYY");
        }
        return this.dateByMode;
    }
}

// --------------------- used by mad list method --------------------- //
export class VisitReportMadListResponseDTO extends UserDTO {
    selected: boolean = true;

    public getFormattedUser(): string {
        return `${this.firstname ? `${this.firstname} ` : ""}${this.lastname ? `${this.lastname} ` : ""}${this.firstname || this.lastname ? "\r\n" : ""}${this.email ? `${this.email} ` : ""}`;
    }
}

export type DateParseMode = "day" | "week" | "month";
