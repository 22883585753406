import { Directive } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

export function validDeZipCodeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (typeof control.value !== "undefined" && control.value !== null && /\d{5}/g.test(control.value)) {
            return null;
        } else {
            return { validDeZipCode: true };
        }
    };
}

@Directive({
    selector: "[appValidDeZipCode]",
    providers: [{ provide: NG_VALIDATORS, useExisting: ValidDeZipCodeValidatorDirective, multi: true }]
})
export class ValidDeZipCodeValidatorDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors | null {
        return validDeZipCodeValidator()(control);
    }
}
