import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode, ConfirmDialogComponent } from "@impacgroup/angular-baselib";
import { ChecklistsService } from "./checklists.service";
import { TranslateService } from "@ngx-translate/core";
import { Checklist } from "../../api-models/Checklist";
import { BsModalService } from "ngx-bootstrap/modal";
import { DomSanitizer } from "@angular/platform-browser";
import { PermissionService } from "@impacgroup/angular-oauth-base";
import { saveAs } from "file-saver";
import { HttpResponse } from "@angular/common/http";

@Component({
    selector: "app-checklistdetail",
    templateUrl: "./checklistdetail.component.html",
    styleUrls: ["checklistdetail.component.scss"]
})
export class ChecklistDetailComponent extends BaseComponent implements OnInit {
    private checklistId: string = null;
    public checklist: Checklist = null;
    public checklistPDF: any;

    constructor(
        private route: ActivatedRoute,
        private checklistsService: ChecklistsService,
        private router: Router,
        private alertService: AlertService,
        private translateService: TranslateService,
        private _location: Location,
        private modalService: BsModalService,
        private permissionService: PermissionService,
        private sanitization: DomSanitizer
    ) {
        super();

        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.checklistId = params.id;
                if (this.checklistId) {
                    this.initEditMode();
                } else {
                    this._location.back();
                }
            })
        );
    }

    ngOnInit(): void {}

    initEditMode() {
        this.MODE = BaseComponentMode.EDIT;

        this.refreshChecklist();

        this.subscriptions.push(
            this.checklistsService.downloadChecklistPDF(this.checklistId).subscribe(
                (response: HttpResponse<Blob>) => {
                    const pdfBlob = new Blob([response.body], { type: "application/pdf" });
                    let pdfBlobURL = URL.createObjectURL(pdfBlob);
                    const trustedUrl = this.sanitization.bypassSecurityTrustResourceUrl(`${pdfBlobURL}`);
                    this.checklistPDF = trustedUrl;
                },
                (err) => {
                    this.router.navigate(["../"], { relativeTo: this.route });
                }
            )
        );
    }

    refreshChecklist() {
        this.subscriptions.push(
            this.checklistsService.checklistDetail(this.checklistId).subscribe(
                (result) => {
                    this.checklist = result;
                },
                (err) => {
                    this.router.navigate(["../"], { relativeTo: this.route });
                }
            )
        );
    }

    downloadChecklist(id: string) {
        this.subscriptions.push(
            this.checklistsService.downloadChecklistPDF(this.checklistId).subscribe((checklistPDFBlob) => {
                const fileName = "checklist_" + this.checklistId + ".pdf";
                saveAs(checklistPDFBlob, fileName);
            })
        );
    }

    save(action: string) {
        if (this.MODE === BaseComponentMode.EDIT) {
            switch (action) {
                case "mark":
                    if (this.permissionService.hasPermission("CHECKLIST.UPDATE.MARK")) {
                        this.subscriptions.push(
                            this.checklistsService.setMarkStateOnChecklist(this.checklistId, true).subscribe((result) => {
                                this.alertService.addSuccess(this.translateService.instant("checklists.detail.edit.message.marked"));
                                this.refreshChecklist();
                            })
                        );
                    } else {
                        this.alertService.addCustomErrorByI18nId(this.translateService.instant("checklists.detail.edit.message.error"));
                    }
                    break;

                case "unmark":
                    if (this.permissionService.hasPermission("CHECKLIST.UPDATE.MARK")) {
                        this.subscriptions.push(
                            this.checklistsService.setMarkStateOnChecklist(this.checklistId, false).subscribe((result) => {
                                this.alertService.addSuccess(this.translateService.instant("checklists.detail.edit.message.unmarked"));
                                this.refreshChecklist();
                            })
                        );
                    } else {
                        this.alertService.addCustomErrorByI18nId(this.translateService.instant("checklists.detail.edit.message.error"));
                    }
                    break;

                case "approve":
                    if (this.permissionService.hasPermission("CHECKLIST.UPDATE.APPROVE")) {
                        this.subscriptions.push(
                            this.checklistsService.setApproveStateOnChecklist(this.checklistId, true).subscribe((result) => {
                                this.alertService.addSuccess(this.translateService.instant("checklists.detail.edit.message.approved"));
                                this.refreshChecklist();
                            })
                        );
                    } else {
                        this.alertService.addCustomErrorByI18nId(this.translateService.instant("checklists.detail.edit.message.error"));
                    }
                    break;

                case "unapprove":
                    if (this.permissionService.hasPermission("CHECKLIST.UPDATE.APPROVE")) {
                        this.subscriptions.push(
                            this.checklistsService.setApproveStateOnChecklist(this.checklistId, false).subscribe((result) => {
                                this.alertService.addSuccess(this.translateService.instant("checklists.detail.edit.message.unapproved"));
                                this.refreshChecklist();
                            })
                        );
                    } else {
                        this.alertService.addCustomErrorByI18nId(this.translateService.instant("checklists.detail.edit.message.error"));
                    }
                    break;

                case "confirm":
                    if (this.permissionService.hasPermission("CHECKLIST.UPDATE.CONFIRM")) {
                        this.subscriptions.push(
                            this.checklistsService.setConfirmStateOnChecklist(this.checklistId, true).subscribe((result) => {
                                this.alertService.addSuccess(this.translateService.instant("checklists.detail.edit.message.confirmed"));
                                this.refreshChecklist();
                            })
                        );
                    } else {
                        this.alertService.addCustomErrorByI18nId(this.translateService.instant("checklists.detail.edit.message.error"));
                    }
                    break;

                case "unconfirm":
                    if (this.permissionService.hasPermission("CHECKLIST.UPDATE.CONFIRM")) {
                        this.subscriptions.push(
                            this.checklistsService.setConfirmStateOnChecklist(this.checklistId, false).subscribe((result) => {
                                this.alertService.addSuccess(this.translateService.instant("checklists.detail.edit.message.unconfirmed"));
                                this.refreshChecklist();
                            })
                        );
                    } else {
                        this.alertService.addCustomErrorByI18nId(this.translateService.instant("checklists.detail.edit.message.error"));
                    }
                    break;

                default:
                    this.alertService.addCustomErrorByI18nId(this.translateService.instant("checklists.detail.edit.message.error"));
                    break;
            }
        }
    }

    back() {
        this._location.back();
    }
}
