import { Component, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { VisitReportsService } from "./visitreports.service";
import { BaseDatatableComponent, IDataTableParameters } from "@impacgroup/angular-baselib";
import { VisitReportListResponseDTO, DateParseMode } from "../../api-models/VisitReport";
import { VisitReportsMainComponent } from "./visitreports.main.component";
import * as moment from "moment";
import { VisitReportsListStateService } from "./visitreports.liststate.service";

@Component({
    selector: "app-visitreports",
    templateUrl: "./visitreports.component.html",
    styleUrls: ["visitreports.component.scss"]
})
export class VisitReportsComponent extends BaseDatatableComponent implements OnInit {
    public UTCDATEFORMAT: string = "";

    @ViewChild(DataTableDirective, { static: false })
    datatableElement: DataTableDirective;
    rows: VisitReportListResponseDTO[] = [];
    totalChecklistCount = 0;

    constructor(
        private visitReportsService: VisitReportsService,
        private translateService: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private parent: VisitReportsMainComponent,
        public visitReportsListStateService: VisitReportsListStateService
    ) {
        super(translateService, {
            stateSaveMode: visitReportsService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_visitreports_list"
        });

        this.dtOptions.order = [[0, "desc"]];
        this.UTCDATEFORMAT = this.visitReportsService.UTCDATEFORMAT;

        // calculate filter options and set standard filters (datemode === month)
        const endMonth = moment();

        if (!this.visitReportsListStateService.filterStartMonth) {
            this.visitReportsListStateService.filterStartMonth = endMonth.format("YYYY-MM");
        }
        if (!this.visitReportsListStateService.filterEndMonth) {
            this.visitReportsListStateService.filterEndMonth = endMonth.format("YYYY-MM");
        }

        const startMonth = moment().subtract(1, "year");
        this.visitReportsListStateService.monthFilterOptions = [];
        while (startMonth.format("YYYY-MM") !== endMonth.format("YYYY-MM")) {
            this.visitReportsListStateService.monthFilterOptions.push({
                key: endMonth.format("YYYY-MM"),
                value: endMonth.format("MMMM / YYYY")
            });

            endMonth.subtract(1, "month");
        }

        // calculate filter options and set standard filters (datemode === week)
        const endWeek = moment();

        if (!this.visitReportsListStateService.filterStartWeek) {
            this.visitReportsListStateService.filterStartWeek = endWeek.format("YYYY-WW");
        }
        if (!this.visitReportsListStateService.filterEndWeek) {
            this.visitReportsListStateService.filterEndWeek = endWeek.format("YYYY-WW");
        }

        const startWeek = moment().subtract(1, "year");
        this.visitReportsListStateService.weekFilterOptions = [];
        while (startWeek.format("YYYY-WW") !== endWeek.format("YYYY-WW")) {
            this.visitReportsListStateService.weekFilterOptions.push({
                key: endWeek.format("YYYY-WW"),
                value: `KW ${endWeek.format("WW / YYYY")}`
            });

            endWeek.subtract(1, "week");
        }

        // set standard filters (datemode === day)
        if (!this.visitReportsListStateService.filterStartDate) {
            this.visitReportsListStateService.filterStartDate = moment().startOf("month").toDate();
        }
        if (!this.visitReportsListStateService.filterEndDate) {
            this.visitReportsListStateService.filterEndDate = moment().endOf("month").toDate();
        }
    }

    ngOnInit() {
        if (!this.visitReportsListStateService.mads) {
            this.subscriptions.push(
                this.visitReportsService.getMADs().subscribe((result) => {
                    this.visitReportsListStateService.mads = result;
                })
            );
        }
    }

    _dataTableTrigger() {
        return (dataTableParameters: IDataTableParameters, callback) => {
            // get api query parameters by datatable
            const queryParams = this.getApiQueryParamsByDataTable(dataTableParameters);

            let from: string;
            if (this.visitReportsListStateService.datemode === "day" && this.visitReportsListStateService.filterStartDate) {
                from = moment(this.visitReportsListStateService.filterStartDate).format("YYYY-MM-DD");
            }
            if (this.visitReportsListStateService.datemode === "week" && this.visitReportsListStateService.filterStartWeek) {
                from = this.visitReportsListStateService.filterStartWeek;
            }
            if (this.visitReportsListStateService.datemode === "month" && this.visitReportsListStateService.filterStartMonth) {
                from = this.visitReportsListStateService.filterStartMonth;
            }

            let to: string;
            if (this.visitReportsListStateService.datemode === "day" && this.visitReportsListStateService.filterEndDate) {
                to = moment(this.visitReportsListStateService.filterEndDate).format("YYYY-MM-DD");
            }
            if (this.visitReportsListStateService.datemode === "week" && this.visitReportsListStateService.filterEndWeek) {
                to = this.visitReportsListStateService.filterEndWeek;
            }
            if (this.visitReportsListStateService.datemode === "month" && this.visitReportsListStateService.filterEndMonth) {
                to = this.visitReportsListStateService.filterEndMonth;
            }

            this.subscriptions.push(
                this.visitReportsService
                    .list({
                        params: queryParams,
                        mode: this.visitReportsListStateService.datemode,
                        mads: this.visitReportsListStateService.mads ? this.visitReportsListStateService.mads.filter((mad) => mad.selected === true).map((mad) => mad._id) : undefined,
                        from,
                        to
                    })
                    .subscribe((result) => {
                        this.rows = result.list;
                        this.totalChecklistCount = result.totalChecklistCount;

                        callback({
                            recordsTotal: result.total,
                            recordsFiltered: result.count,
                            data: []
                        });
                    })
            );
        };
    }

    _dataTableColums() {
        return [
            { data: "dateByMode", orderable: true },
            { data: "user", orderable: false },
            { data: "checklistCount", orderable: true },
            { data: "actions", orderable: false }
        ];
    }

    setDatemode(mode: DateParseMode) {
        this.visitReportsListStateService.datemode = mode;
        this.rerenderDatatable();
    }

    selectAllMads() {
        this.visitReportsListStateService.mads.forEach((mad) => {
            mad.selected = true;
        });
        this.rerenderDatatable();
    }

    deselectAllMads() {
        this.visitReportsListStateService.mads.forEach((mad) => {
            mad.selected = false;
        });
        this.rerenderDatatable();
    }

    dateFilterChanged() {
        if (typeof this.dtElement !== "undefined" && typeof this.dtElement.dtInstance !== "undefined") {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.draw();
            });
        }
    }

    read(obj: VisitReportListResponseDTO) {
        this.router.navigate(["..", "checklists", "overview"], {
            relativeTo: this.route,
            queryParams: {
                mode: this.visitReportsListStateService.datemode,
                date: obj.dateByMode,
                userFilter: obj.user?._id
            }
        });
    }
}
