import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { InterestedPartiesService } from "./interestedparties.service";
import { AlertService, BaseDatatableComponent, ConfirmDialogComponent, IDataTableParameters } from "@impacgroup/angular-baselib";
import { InterestedParty } from "../../api-models/InterestedParty";
import { InterestedPartiesMainComponent } from "./interestedparties.main.component";

@Component({
    selector: "app-interestedparties",
    templateUrl: "./interestedparties.component.html",
    styleUrls: ["interestedparties.component.scss"]
})
export class InterestedPartiesComponent extends BaseDatatableComponent implements OnInit {
    public UTCDATEFORMAT: string = "";

    rows: InterestedParty[] = [];

    constructor(private interestedPartiesService: InterestedPartiesService, private translateService: TranslateService, private modalService: BsModalService, private alertService: AlertService, parent: InterestedPartiesMainComponent) {
        super(translateService, {
            stateSaveMode: interestedPartiesService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_interestedparties_list"
        });

        this.dtOptions.order = [[4, "desc"]];
        this.UTCDATEFORMAT = this.interestedPartiesService.UTCDATEFORMAT;
    }

    ngOnInit() {}

    _dataTableTrigger() {
        return (dataTableParameters: IDataTableParameters, callback) => {
            // get api query parameters by datatable
            const queryParams = this.getApiQueryParamsByDataTable(dataTableParameters);

            this.subscriptions.push(
                this.interestedPartiesService.interestedParties(queryParams).subscribe((result) => {
                    this.rows = result.list;

                    callback({
                        recordsTotal: result.total,
                        recordsFiltered: result.count,
                        data: []
                    });
                })
            );
        };
    }

    _dataTableColums() {
        return [
            { data: "name", orderable: true },
            { data: "postcode", orderable: true },
            { data: "contact", orderable: false },
            { data: "notes", orderable: false },
            { data: "created", orderable: true },
            { data: "updated", orderable: true },
            { data: "actions", orderable: false }
        ];
    }

    deleteInterestedParty(id: string) {
        const initialState = {
            title: this.translateService.instant("interestedparties.list.delete.title"),
            message: this.translateService.instant("interestedparties.list.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            this.subscriptions.push(
                this.interestedPartiesService.deleteInterestedParties(id).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("interestedparties.list.delete.success"));
                    this.rerenderDatatable();
                })
            );
        };
    }
}
