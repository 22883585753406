import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TooltipModule } from "ngx-bootstrap/tooltip";

import { ChecklistOverviewComponent } from "./checklistoverview.component";
import { ChecklistsService, ChecklistsConfigService, IChecklistsConfigService } from "./checklists.service";
import { ChecklistDetailComponent } from "./checklistdetail.component";
import { ChecklistExportComponent } from "./checklistexport.component";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { ChecklistsMainComponent } from "./checklists.main.component";

@NgModule({
    declarations: [ChecklistsMainComponent, ChecklistOverviewComponent, ChecklistDetailComponent, ChecklistExportComponent],
    imports: [CommonModule, RouterModule, FormsModule, DataTablesModule, PopoverModule.forRoot(), TooltipModule.forRoot(), BsDropdownModule, ModalModule, NgSelectModule, TranslateModule, OAuthModule],
    exports: [],
    providers: []
})
export class ChecklistsModule {
    static forRoot(checklistsConfig: IChecklistsConfigService): ModuleWithProviders<ChecklistsModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: ChecklistsModule,
            providers: [
                ChecklistsService,
                {
                    provide: ChecklistsConfigService,
                    useValue: checklistsConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                redirectTo: "overview",
                pathMatch: "full"
            },
            {
                path: "overview",
                component: ChecklistsMainComponent,
                children: [
                    {
                        path: "",
                        component: ChecklistOverviewComponent
                    },
                    {
                        path: ":id",
                        component: ChecklistDetailComponent
                    }
                ]
            },
            {
                path: "export",
                component: ChecklistExportComponent
            }
        ];
    }
}
