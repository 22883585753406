import { Exclude, Type } from "class-transformer";

export class ShopItem {
    _id?: string;

    name: string;
    materialNumber: string;
    category: CategoryType;

    version?: number;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    created?: Date;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    updated?: Date;

    public getCategoryI18N(): string {
        return getCategoryI18N(this.category);
    }
}

function lowerwounderscore(str: string): string {
    return str.toLowerCase().replace(/_/g, "");
}

export function getCategoryI18N(category: CategoryType): string {
    const norm_id = lowerwounderscore(category);
    return `shopitems.data.category.${norm_id}`;
}

export type CategoryType = "POS_MATERIALIEN" | "GIVE_AWAYS";
export const CategoryTypeArray: CategoryType[] = ["POS_MATERIALIEN", "GIVE_AWAYS"];
