import { Component } from "@angular/core";
import { BaseComponent } from "@impacgroup/angular-baselib";
import { ChecklistsService } from "./checklists.service";
import { saveAs } from "file-saver";
import * as moment from "moment";
import { ActivatedRoute, Router, RoutesRecognized } from "@angular/router";
import { Location } from "@angular/common";

@Component({
    selector: "app-checklist-export",
    templateUrl: "./checklistexport.component.html"
})
export class ChecklistExportComponent extends BaseComponent {
    constructor(private checklistsService: ChecklistsService, private router: Router, private route: ActivatedRoute, private _location: Location) {
        super();

        this.subscriptions.push(
            this.checklistsService.exportChecklists().subscribe((checklistsCSVBlob) => {
                const fileName = `${moment().utc().format("YYYY-MM-DDTHH-mm-ss")}_checklists.csv`;
                saveAs(checklistsCSVBlob, fileName);

                if (typeof history === "object" && typeof history.state === "object" && typeof history.state.navigationId === "number" && history.state.navigationId != 1) {
                    this._location.back();
                } else {
                    this.router.navigate(["../overview"], { relativeTo: this.route });
                }
            })
        );
    }
}
