<div class="content__inner">
    <header class="content__title">
        <h1>{{ "visitreports.list.title" | translate }}</h1>
        <small>{{ "visitreports.list.subtitle" | translate }}</small>
    </header>

    <div class="card" *appHasPermission="'VISITREPORT.LIST'">
        <div class="card-body">
            <h4 class="card-title">{{ "visitreports.list.card.title" | translate }}</h4>
            <div class="actions">
                <!-- <button class="float-right btn btn-outline-secondary btn--icon-text" *appHasPermission="'CHECKLIST.EXPORTRESULTS'" (click)="downloadChecklistResultsCSV()">
                    <i class="zmdi zmdi-download"></i> {{ "checklists.overview.list.card.export" | translate }}
                </button> -->
            </div>

            <div class="form-group" *ngIf="visitReportsListStateService.mads">
                <label>{{ "visitreports.list.filter.mad.title" | translate }}</label>
                <div class="checkbox" *ngFor="let mad of visitReportsListStateService.mads">
                    <input type="checkbox" id="madfilter_{{ mad._id }}" [checked]="mad.selected" (change)="mad.selected = !mad.selected; rerenderDatatable()" />
                    <label class="checkbox__label" for="madfilter_{{ mad._id }}"> {{ mad.getFormattedUser() }}</label>
                </div>
            </div>

            <div class="row mt-1">
                <div class="col-12">
                    <button type="button" (click)="selectAllMads()" class="btn btn-success btn--icon-text float-left mr-2">{{ "visitreports.buttons.selectAll" | translate }}</button>
                    <button type="button" (click)="deselectAllMads()" class="btn btn-success btn--icon-text float-left mr-2">{{ "visitreports.buttons.deselectAll" | translate }}</button>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12">
                    <label>{{ "visitreports.mode.title" | translate }}</label>
                </div>
                <div class="col-12">
                    <button type="button" (click)="setDatemode('day')" class="btn btn-success btn--icon-text float-left mr-2" [class.active]="visitReportsListStateService.datemode === 'day'">
                        {{ "visitreports.buttons.day" | translate }}
                    </button>
                    <button type="button" (click)="setDatemode('week')" class="btn btn-success btn--icon-text float-left mr-2" [class.active]="visitReportsListStateService.datemode === 'week'">
                        {{ "visitreports.buttons.week" | translate }}
                    </button>
                    <button type="button" (click)="setDatemode('month')" class="btn btn-success btn--icon-text float-left mr-2" [class.active]="visitReportsListStateService.datemode === 'month'">
                        {{ "visitreports.buttons.month" | translate }}
                    </button>
                </div>
            </div>

            <div class="row mt-4" *ngIf="visitReportsListStateService.datemode === 'day'">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "visitreports.filter.startdate.title" | translate }}</label>
                        <div class="row">
                            <div class="col-auto">
                                <input
                                    class="form-control"
                                    type="text"
                                    placeholder="{{ 'visitreports.filter.startdate.placeholder' | translate }}"
                                    bsDatepicker
                                    (bsValueChange)="dateFilterChanged()"
                                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showMeridian: false, containerClass: 'theme-blue' }"
                                    [(ngModel)]="visitReportsListStateService.filterStartDate"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "visitreports.filter.enddate.title" | translate }}</label>
                        <div class="row">
                            <div class="col-auto">
                                <input
                                    class="form-control"
                                    type="text"
                                    placeholder="{{ 'visitreports.filter.enddate.placeholder' | translate }}"
                                    bsDatepicker
                                    (bsValueChange)="dateFilterChanged()"
                                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showMeridian: false, containerClass: 'theme-blue' }"
                                    [(ngModel)]="visitReportsListStateService.filterEndDate"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-4" *ngIf="visitReportsListStateService.datemode === 'week'">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "visitreports.filter.startdate.title" | translate }}</label>
                        <div class="row">
                            <div class="col-12">
                                <ng-select
                                    name="filterStartWeek"
                                    [(ngModel)]="visitReportsListStateService.filterStartWeek"
                                    [items]="visitReportsListStateService.weekFilterOptions"
                                    (change)="dateFilterChanged()"
                                    bindLabel="value"
                                    bindValue="key"
                                    [placeholder]="'visitreports.filter.startdate.placeholder' | translate"
                                    [clearable]="false"
                                >
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "visitreports.filter.enddate.title" | translate }}</label>
                        <div class="row">
                            <div class="col-12">
                                <ng-select
                                    name="filterEndWeek"
                                    [(ngModel)]="visitReportsListStateService.filterEndWeek"
                                    [items]="visitReportsListStateService.weekFilterOptions"
                                    (change)="dateFilterChanged()"
                                    bindLabel="value"
                                    bindValue="key"
                                    [placeholder]="'visitreports.filter.startdate.placeholder' | translate"
                                    [clearable]="false"
                                >
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-4" *ngIf="visitReportsListStateService.datemode === 'month'">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "visitreports.filter.startdate.title" | translate }}</label>
                        <div class="row">
                            <div class="col-12">
                                <ng-select
                                    name="filterStartMonth"
                                    [(ngModel)]="visitReportsListStateService.filterStartMonth"
                                    [items]="visitReportsListStateService.monthFilterOptions"
                                    (change)="dateFilterChanged()"
                                    bindLabel="value"
                                    bindValue="key"
                                    [placeholder]="'visitreports.filter.startdate.placeholder' | translate"
                                    [clearable]="false"
                                >
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "visitreports.filter.enddate.title" | translate }}</label>
                        <div class="row">
                            <div class="col-12">
                                <ng-select
                                    name="filterEndMonth"
                                    [(ngModel)]="visitReportsListStateService.filterEndMonth"
                                    [items]="visitReportsListStateService.monthFilterOptions"
                                    (change)="dateFilterChanged()"
                                    bindLabel="value"
                                    bindValue="key"
                                    [placeholder]="'visitreports.filter.startdate.placeholder' | translate"
                                    [clearable]="false"
                                >
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <table datatable class="table table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th id="dateByMode">
                            {{
                                visitReportsListStateService.datemode === "month"
                                    ? ("visitreports.props.month" | translate)
                                    : visitReportsListStateService.datemode === "week"
                                    ? ("visitreports.props.week" | translate)
                                    : ("visitreports.props.day" | translate)
                            }}
                        </th>
                        <th id="user">{{ "visitreports.props.user" | translate }}</th>
                        <th id="checklistCount">{{ "visitreports.props.checklistCount" | translate }}</th>
                        <th id="actions"></th>
                    </tr>
                </thead>
                <tbody *ngIf="rows?.length != 0">
                    <tr *ngFor="let row of rows">
                        <td>{{ row.dateByMode ? row.getFormattedDate(visitReportsListStateService.datemode) : "" }}</td>
                        <td>{{ row.user ? row.getFormattedUser() : "" }}</td>
                        <td>{{ row.checklistCount }}</td>
                        <td class="text-right nowrap">
                            <i (click)="read(row)" class="zmdi zmdi-eye zmdi-hc-fw tableIcon" *appHasPermission="'CHECKLIST.LIST'" [tooltip]="'visitreports.buttontooltips.view' | translate" placement="bottom"></i>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="rows?.length == 0">
                    <tr>
                        <td colspan="6" class="no-data-available">{{ "global.datatable.empty" | translate }}</td>
                    </tr>
                </tbody>

                <tbody></tbody>
            </table>

            <div class="row">
                <div class="col-12 mt-5">
                    <label>{{ "visitreports.list.totalChecklistCount" | translate }} {{ totalChecklistCount }}</label>
                </div>
            </div>
        </div>
    </div>
</div>
