import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode, ConfirmDialogComponent, IApiListQueryParameter } from "@impacgroup/angular-baselib";
import { ToursService } from "./tours.service";
import { TranslateService } from "@ngx-translate/core";
import { TourDetailModel, TourDistributorsTransferModel } from "../../api-models/Tour";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { TourDistributorListModel } from "src/app/api-models/TourDistributor";
import * as moment from "moment";
import { TourDistributionDialogComponent } from "src/app/modal/tourdistribution-dialog.component";
import { DistributorStatus, DistributorStatusArray } from "src/app/api-models/Checklist";
import { DistributorsService } from "../distributors/distributors.service";
import { saveAs } from "file-saver";

type TourTransferMode = "copy" | "move";

@Component({
    selector: "app-tourdetail",
    templateUrl: "./tourdetail.component.html",
    styleUrls: ["tourdetail.component.scss"]
})
export class TourDetailComponent extends BaseComponent implements OnInit {
    public UTCDATEFORMAT: string = "";

    private tourId: string = null;
    public tour: TourDetailModel = null;
    public tourDistributors: TourDistributorListModel[] = [];

    selectedRows: {
        [key: string]: {
            selected: boolean;
            visited: boolean;
        };
    } = {};

    selectedCopyTotal: number = 0;
    selectedMoveTotal: number = 0;

    bsModalRef: BsModalRef<TourDistributionDialogComponent>;

    // search filters
    statusOptions = DistributorStatusArray;
    isStatus?: DistributorStatus | "undefined";
    fromPostCode?: string;
    toPostCode?: string;
    city?: string;

    saleslineFilter = "";
    selectedLifecycleStatusFilter?: string;
    lifecycleStatusFilterItems: string[] = [];

    numbersOnlyPattern = /^\d*$/;
    private debounceTime = 300;
    private debounceFilterChangedTimeout?: number;

    constructor(
        private route: ActivatedRoute,
        private toursService: ToursService,
        private distributorsService: DistributorsService,
        private router: Router,
        private alertService: AlertService,
        private translateService: TranslateService,
        private _location: Location,
        private modalService: BsModalService
    ) {
        super();

        this.UTCDATEFORMAT = this.toursService.UTCDATEFORMAT;

        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.tourId = params.id;
                if (this.tourId) {
                    this.initEditMode();
                } else {
                    this.initAddMode();
                }
            })
        );
    }

    ngOnInit(): void {}

    initAddMode() {
        this.MODE = BaseComponentMode.ADD;
        this.tour = {};
    }

    initEditMode() {
        this.MODE = BaseComponentMode.EDIT;

        const tourSub = this.toursService.read(this.tourId).subscribe(
            (result) => {
                this.tour = result;
            },
            (err) => {
                this.router.navigate(["../"], { relativeTo: this.route });
            }
        );

        this.loadDistributors();

        this.subscriptions.push(tourSub);

        this.subscriptions.push(
            this.distributorsService.getLifecycleStatusItems().subscribe((result) => {
                this.lifecycleStatusFilterItems = result;
            })
        );
    }

    save() {
        if (this.MODE === BaseComponentMode.ADD) {
            this.subscriptions.push(
                this.toursService.create(this.tour).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("tours.detail.add.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }
        if (this.MODE === BaseComponentMode.EDIT) {
            this.subscriptions.push(
                this.toursService.update(this.tour).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("tours.detail.edit.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }
    }

    preventChangeVisitedCheckbox(event: Event, tourDistributor: TourDistributorListModel) {
        const target = event.target as HTMLInputElement;
        const newCheckboxState = target.checked;

        // Prevent toggling checkbox until the modal is answered (or not)
        target.checked = !newCheckboxState;
        tourDistributor.visited = !newCheckboxState;

        return newCheckboxState;
    }

    // AYC-144: Can be reactivated here
    changeVisitedCheckbox(event: Event, tourDistributor: TourDistributorListModel) {
        const newCheckboxState = this.preventChangeVisitedCheckbox(event, tourDistributor);

        if (newCheckboxState === true) {
            this.setDistributorVisited(tourDistributor);
        } else {
            this.unsetDisitributorVisited(tourDistributor);
        }
    }

    back() {
        this._location.back();
    }

    selectAll() {
        Object.entries(this.selectedRows).map((row) => {
            row[1].selected = true;
        });
        this.onSelectionToggle();
    }

    deselectAll() {
        Object.entries(this.selectedRows).map((row) => {
            row[1].selected = false;
        });
        this.onSelectionToggle();
    }

    onSelectionToggle() {
        this.selectedCopyTotal = Object.values(this.selectedRows).filter((value) => value.selected).length;
        this.selectedMoveTotal = Object.values(this.selectedRows).filter((value) => value.selected && !value.visited).length;
    }

    showCopyTourDistributionDialog() {
        this.showTourDistributionDialog("copy");
    }

    showMoveTourDistributionDialog() {
        this.showTourDistributionDialog("move");
    }

    showChecklistDetail(id: string) {
        this.router.navigate(["/checklists/overview/" + id]);
    }

    private showTourDistributionDialog(tourTransferMode: TourTransferMode) {
        const queryParams: IApiListQueryParameter = { sort: "fromDate|asc" };
        const toursListSub = this.toursService.list(queryParams, true).subscribe((result) => {
            const selectorOptions = result.list
                .filter((model) => {
                    return model._id !== this.tourId;
                })
                .map((model) => {
                    return {
                        name: model.name,
                        fromDate: moment(model.fromDate).format("YYYY-MM-DD").toString(),
                        toDate: moment(model.toDate).format("YYYY-MM-DD").toString(),
                        _id: model._id
                    };
                });

            const initialState = {
                selectorOptions: selectorOptions
            };

            this.bsModalRef = this.modalService.show(TourDistributionDialogComponent, { initialState });
            const createdSub = this.bsModalRef.content.created.take(1).subscribe((tour) => {
                this.createNewTour(tour, tourTransferMode);
            });
            const confirmedSub = this.bsModalRef.content.confirmed.take(1).subscribe((tour) => {
                this.updateTourDistributors(tour, tourTransferMode);
            });
            this.subscriptions.push(createdSub, confirmedSub);
        });
        this.subscriptions.push(toursListSub);
    }

    deleteTourDistributor(tourDistributorId: string) {
        const initialState = {
            title: this.translateService.instant("tours.detail.tourDistributors.delete.title"),
            message: this.translateService.instant("tours.detail.tourDistributors.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            this.subscriptions.push(
                this.toursService.deleteTourDistributor({ tourId: this.tourId, tourDistributorId: tourDistributorId }).subscribe(() => {
                    this.alertService.addSuccess(this.translateService.instant("tours.detail.tourDistributors.delete.success"));
                    this.loadDistributors();
                })
            );
        };
    }

    public csvExport() {
        this.subscriptions.push(
            this.toursService
                .csvExportTourDistributors({
                    tourId: this.tourId,
                    params: {},
                    status: this.isStatus && this.isStatus !== "undefined" ? this.isStatus : undefined,
                    fromPostCode: this.fromPostCode,
                    toPostCode: this.toPostCode,
                    city: this.city,
                    saleslineFilter: this.saleslineFilter,
                    selectedLifecycleStatusFilter: this.selectedLifecycleStatusFilter && this.selectedLifecycleStatusFilter !== "undefined" ? this.selectedLifecycleStatusFilter : undefined
                })
                .subscribe((csvBlob) => {
                    const fileName = `${moment().utc().format("YYYY-MM-DDTHH-mm-ss")}_tour.csv`;
                    saveAs(csvBlob, fileName, { autoBom: true });
                })
        );
    }

    private loadDistributors() {
        const tourDistributorsSub = this.toursService
            .listTourDistributors({
                tourId: this.tourId,
                params: {},
                status: this.isStatus && this.isStatus !== "undefined" ? this.isStatus : undefined,
                fromPostCode: this.fromPostCode,
                toPostCode: this.toPostCode,
                city: this.city,
                saleslineFilter: this.saleslineFilter,
                selectedLifecycleStatusFilter: this.selectedLifecycleStatusFilter && this.selectedLifecycleStatusFilter !== "undefined" ? this.selectedLifecycleStatusFilter : undefined
            })
            .subscribe(
                (result) => {
                    this.tourDistributors = result.list;
                    this.selectedRows = this.tourDistributors.reduce<Record<string, { visited: boolean; selected: boolean }>>((acc, cur) => Object.assign(acc, { [cur._id]: { visited: cur.visited, selected: !cur.visited } }), {});
                    this.onSelectionToggle();
                },
                (err) => {
                    this.router.navigate(["../"], { relativeTo: this.route });
                }
            );

        this.subscriptions.push(tourDistributorsSub);
    }

    private setDistributorVisited(tourDistributor: TourDistributorListModel) {
        const initialState = {
            title: this.translateService.instant("tours.detail.tourDistributors.setVisited.title"),
            message: this.translateService.instant("tours.detail.tourDistributors.setVisited.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            this.subscriptions.push(
                this.toursService.setTourDistributorVisited({ tourId: this.tourId, tourDistributorId: tourDistributor._id, tourDistributorVersion: tourDistributor.version }).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("tours.detail.tourDistributors.setVisited.success"));
                    this.loadDistributors();
                })
            );
        };
    }

    private unsetDisitributorVisited(tourDistributor: TourDistributorListModel) {
        const initialState = {
            title: this.translateService.instant("tours.detail.tourDistributors.unsetVisited.title"),
            message: this.translateService.instant("tours.detail.tourDistributors.unsetVisited.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            this.subscriptions.push(
                this.toursService.unsetTourDistributorVisited({ tourId: this.tourId, tourDistributorId: tourDistributor._id, tourDistributorVersion: tourDistributor.version }).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("tours.detail.tourDistributors.unsetVisited.success"));
                    this.loadDistributors();
                })
            );
        };
    }

    private createNewTour(tour: TourDetailModel, mode: TourTransferMode) {
        this.subscriptions.push(
            this.toursService.create(tour).subscribe((result) => {
                this.updateTourDistributors(result._id, mode);
            })
        );
    }

    private updateTourDistributors(tourId: string, mode: TourTransferMode) {
        const tourDistributorIds: string[] = Object.entries(this.selectedRows)
            // filter selected tours
            .filter((entry) => entry[1].selected)

            // filter not visited tours, if mode is move, else dont filter
            .filter((entry) => (mode === "move" ? !entry[1].visited : true))

            // map to tourDistributorId
            .map((entry) => entry[0]);

        const tourDistributors: TourDistributorsTransferModel = {
            mode,
            targetTourId: tourId,
            tourDistributorIds
        };

        const transferSub = this.toursService.transferTourDistributors({ tourId: this.tourId, obj: tourDistributors }).subscribe((result) => {
            // ignore failcounter on move, because the source tour distributor will be simply removed
            if (result.failcounter === 0 || mode === "move") {
                this.alertService.addSuccess(this.translateService.instant("tours.dialog.success"));
            } else {
                this.alertService.addCustomError(this.translateService.instant(result.failcounter < 2 ? "tours.dialog.partialSuccess.singular" : "tours.dialog.partialSuccess.plural", { count: result.failcounter }));
            }

            this.bsModalRef.hide();

            this.loadDistributors();
        });

        this.subscriptions.push(transferSub);
    }

    // search filters
    onChangeFilter() {
        this.loadDistributors();
    }

    onStatusSelected() {
        this.onChangeFilter();
    }

    postCodeChanged(postCode: string) {
        if (this.debounceFilterChangedTimeout) {
            window.clearTimeout(this.debounceFilterChangedTimeout);
        }
        this.debounceFilterChangedTimeout = window.setTimeout(() => {
            if (!this.numbersOnlyPattern.test(postCode)) {
                return;
            }

            this.onChangeFilter();
        }, this.debounceTime);
    }

    cityChanged() {
        this.searchInputChanged();
    }

    saleslineChanged() {
        this.searchInputChanged();
    }

    searchInputChanged() {
        if (this.debounceFilterChangedTimeout) {
            window.clearTimeout(this.debounceFilterChangedTimeout);
        }
        this.debounceFilterChangedTimeout = window.setTimeout(() => {
            this.onChangeFilter();
        }, this.debounceTime);
    }
}
