import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ValidDeZipCodeValidatorDirective } from "./valid-de-zip-code.directive";

@NgModule({
    declarations: [ValidDeZipCodeValidatorDirective],
    imports: [CommonModule],
    exports: [ValidDeZipCodeValidatorDirective]
})
export class ValidDeZipCodeModule {}
