import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ValidJsonValidatorDirective } from "./valid-json.directive";

@NgModule({
    declarations: [ValidJsonValidatorDirective],
    imports: [CommonModule],
    exports: [ValidJsonValidatorDirective]
})
export class ValidJsonModule {}
