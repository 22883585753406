import { Exclude, Type } from "class-transformer";

export class ChecklistTemplate {
    _id?: string;

    content: object;
    agreement: string;
    photosGold: object;
    photosNormal: object;
    photosSilber: object;

    version?: number;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    created?: Date;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    updated?: Date;
}

export class ChecklistTemplateTemporary {
    content: string;
    photosGold: string;
    photosNormal: string;
    photosSilber: string;
}
