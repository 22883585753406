import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DateLowerLimitDirective } from "./valid-date-lower-limit.directive";

@NgModule({
    declarations: [DateLowerLimitDirective],
    imports: [CommonModule],
    exports: [DateLowerLimitDirective]
})
export class ValidDateLowerLimitModule {}
