import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { PostcodeAreasService } from "./postcodeareas.service";
import { AlertService, BaseDatatableComponent, ConfirmDialogComponent, IDataTableParameters } from "@impacgroup/angular-baselib";
import { PostcodeArea } from "../../api-models/PostcodeArea";
import { PostcodeAreasMainComponent } from "./postcodeareas.main.component";

@Component({
    selector: "app-postcodeareas",
    templateUrl: "./postcodeareas.component.html",
    styleUrls: ["postcodeareas.component.scss"]
})
export class PostcodeAreasComponent extends BaseDatatableComponent implements OnInit {
    public UTCDATEFORMAT: string = "";

    rows: PostcodeArea[] = [];

    constructor(
        private postcodeAreasService: PostcodeAreasService,
        private translateService: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private alertService: AlertService,
        parent: PostcodeAreasMainComponent
    ) {
        super(translateService, {
            stateSaveMode: postcodeAreasService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_postcodeareas_list"
        });

        this.dtOptions.order = [[2, "asc"]];
        this.UTCDATEFORMAT = this.postcodeAreasService.UTCDATEFORMAT;
    }

    ngOnInit() {}

    _dataTableTrigger() {
        return (dataTableParameters: IDataTableParameters, callback) => {
            // get api query parameters by datatable
            const queryParams = this.getApiQueryParamsByDataTable(dataTableParameters);

            this.subscriptions.push(
                this.postcodeAreasService.postcodeAreas(queryParams).subscribe((result) => {
                    this.rows = result.list;

                    callback({
                        recordsTotal: result.total,
                        recordsFiltered: result.count,
                        data: []
                    });
                })
            );
        };
    }

    _dataTableColums() {
        return [
            { data: "postcodeStart", orderable: true },
            { data: "postcodeEnd", orderable: true },
            { data: "created", orderable: true },
            { data: "updated", orderable: true },
            { data: "actions", orderable: false }
        ];
    }

    addPostcodeArea() {
        this.router.navigate(["add"], { relativeTo: this.route });
    }

    editPostcodeArea(id: string) {
        this.router.navigate([id], { relativeTo: this.route });
    }

    deletePostcodeArea(id: string) {
        const initialState = {
            title: this.translateService.instant("postcodeareas.list.delete.title"),
            message: this.translateService.instant("postcodeareas.list.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            this.subscriptions.push(
                this.postcodeAreasService.deletePostcodeArea(id).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("postcodeareas.list.delete.success"));
                    this.rerenderDatatable();
                })
            );
        };
    }
}
