<div class="content__inner">
    <header class="content__title">
        <h1>{{ "distributors.list.title" | translate }}</h1>
        <small>{{ "distributors.list.subtitle" | translate }}</small>

        <div class="actions">
            <button *appHasPermission="'DISTRIBUTORS.UPDATE'" (click)="fileInput.click()" class="btn btn-success btn--icon-text" type="button">
                <i class="zmdi zmdi-collection-plus"></i> {{ "distributors.list.upload.button" | translate }}
                <input #fileInput type="file" (change)="uploadDistributorList($event.target.files)" style="display: none" />
            </button>
        </div>
    </header>

    <div class="card" *appHasPermission="'DISTRIBUTORS.READ'">
        <div class="card-body">
            <h4 class="card-title">{{ "distributors.list.card.title" | translate }}</h4>

            <form name="form" class="row" *ngIf="distributorImportOverview" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "distributors.props.quantity" | translate }}</label>
                        <input name="postcodeStart" type="text" class="form-control" [ngModel]="distributorImportOverview.quantity" readonly="readonly" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "distributors.props.lastImportDate" | translate }}</label>
                        <input name="lastImportDate" type="text" class="form-control" [ngModel]="distributorImportOverview.lastImportDate | date: distributorsService.dateFormat" readonly="readonly" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
