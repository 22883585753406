import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { ToursService } from "./tours.service";
import { AlertService, BaseDatatableComponent, ConfirmDialogComponent, IDataTableParameters } from "@impacgroup/angular-baselib";
import { TourDialogModel, TourDistributorsCreateModel, TourListModel } from "../../api-models/Tour";
import { ToursMainComponent } from "./tours.main.component";
import { PermissionService } from "@impacgroup/angular-oauth-base";

@Component({
    selector: "app-tours",
    templateUrl: "./tours.component.html",
    styleUrls: ["tours.component.scss"]
})
export class ToursComponent extends BaseDatatableComponent implements OnInit {
    public UTCDATEFORMAT: string = "";
    public hasToursEditPermission = this.permissionService.hasPermission("TOURS.EDIT");

    rows: TourListModel[] = [];

    constructor(
        private toursService: ToursService,
        private translateService: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private alertService: AlertService,
        private permissionService: PermissionService,
        parent: ToursMainComponent
    ) {
        super(translateService, {
            stateSaveMode: toursService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_tours_list"
        });

        this.dtOptions.order = [[1, "desc"]];
        this.UTCDATEFORMAT = this.toursService.UTCDATEFORMAT;
    }

    ngOnInit() {}

    _dataTableTrigger() {
        return (dataTableParameters: IDataTableParameters, callback) => {
            // get api query parameters by datatable
            const queryParams = this.getApiQueryParamsByDataTable(dataTableParameters);

            this.subscriptions.push(
                this.toursService.list(queryParams).subscribe((result) => {
                    this.rows = result.list;

                    callback({
                        recordsTotal: result.total,
                        recordsFiltered: result.count,
                        data: []
                    });
                })
            );
        };
    }

    _dataTableColums() {
        return [
            { data: "name", orderable: true },
            { data: "fromDate", orderable: true },
            { data: "toDate", orderable: true },
            { data: "visited", orderable: false },
            { data: "createdByMail", orderable: false },
            { data: "created", orderable: true },
            { data: "updated", orderable: true },
            { data: "actions", orderable: false }
        ];
    }

    addTour() {
        this.router.navigate(["add"], { relativeTo: this.route });
    }

    editTour(id: string) {
        this.router.navigate([id], { relativeTo: this.route });
    }

    deleteTour(id: string) {
        const initialState = {
            title: this.translateService.instant("tours.list.delete.title"),
            message: this.translateService.instant("tours.list.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            this.subscriptions.push(
                this.toursService.delete(id).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("tours.list.delete.success"));
                    this.rerenderDatatable();
                })
            );
        };
    }

    duplicateTour(id: string, name: string) {
        this.subscriptions.push(
            this.toursService.duplicate(id, this.translateService.instant("tours.list.duplicate.postfix", { originalName: name })).subscribe((result) => {
                this.alertService.addSuccess(this.translateService.instant("tours.list.duplicate.success"));
                this.editTour(result._id);
            })
        );
    }
}
