import { Exclude, Type } from "class-transformer";

export class Checklist {
    _id?: string;

    distributor: Distributor;
    distributorObj: DistributorObj;

    user: User;
    userObj: UserObj;

    differenceDetected: boolean;
    approved: boolean;
    approvalConfirmed: boolean;
    marked: boolean;

    shopAccessible?: boolean;

    version?: number;

    hideVisitedDate?: boolean;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    visited?: Date;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    created?: Date;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    updated?: Date;

    public formatDistributor(): string {
        const distributor = this.distributorObj;

        return `${distributor.name}\r\n${distributor.city}, ${distributor.street}`;
    }

    public formatUser(): string {
        const user = this.userObj;

        return `${user.firstname ? `${user.firstname} ` : ""}${user.lastname ? `${user.lastname} ` : ""}${user.firstname || user.lastname ? "\r\n" : ""}${user.email ? `${user.email} ` : ""}`;
    }

    public approvalStatus(): Status {
        if (this.approvalConfirmed) {
            return Status.Confirmed;
        }
        if (this.approved) {
            return Status.Approved;
        }
        return Status.Neutral;
    }

    public distributorAccessible(): DistributorAccessible {
        if (this.shopAccessible === true) {
            return DistributorAccessible.Accessible;
        }
        if (this.shopAccessible === false) {
            return DistributorAccessible.NotAccessible;
        }

        return DistributorAccessible.Unknown;
    }
}

export class Distributor {
    vo: string;
    name: string;
    street: string;
    postcode: string;
    city: string;
    country: string;
    disabled: boolean;
    postcodeNumber: number;

    lifecycleStatus?: string;
    salesline?: string;

    checklistLastCreatedBy: User;
    checklistLastCreatedByObj: UserObj;

    status: DistributorStatus;

    public formatChecklistLastCreatedBy(): string {
        const user = this.checklistLastCreatedByObj;
        if (user) {
            return `${user.firstname ? `${user.firstname} ` : ""}${user.lastname ? `${user.lastname} ` : ""}`;
        } else {
            return "";
        }
    }
}

export type DistributorStatus = "normal" | "silber" | "gold";
export const DistributorStatusArray: DistributorStatus[] = ["normal", "silber", "gold"];

export class DistributorObj extends Distributor {
    _id?: string;
    version: number;
}

export class User {
    firstname: string;
    lastname: string;
    email: string;
    comment: string;
}

export class UserObj extends User {
    _id?: string;
}

export enum Status {
    Neutral,
    DifferenceDetected,
    Approved,
    Confirmed
}

export enum DistributorAccessible {
    Unknown,
    Accessible,
    NotAccessible
}
