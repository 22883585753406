import { Exclude, Type } from "class-transformer";

export class FlyerImage {
    _id?: string;

    description: string;
    filename: string;

    version?: number;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    created?: Date;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    updated?: Date;
}
