import { Exclude, Type } from "class-transformer";

export class InterestedParty {
    _id?: string;

    name: string;
    street: string;
    postcode: string;
    city: string;
    country: string;

    contactPerson?: string;
    email?: string;
    phone?: string;

    notes?: string;

    version?: number;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    created?: Date;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    updated?: Date;

    public address(): string {
        return `${this.postcode} ${this.city},  ${this.street}`;
    }

    public contact(): string {
        return `${this.contactPerson ? `${this.contactPerson} ` : ""}${this.email ? `${this.email} ` : ""}${this.phone ? `${this.phone} ` : ""}`;
    }
}
