import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode, ConfirmDialogComponent } from "@impacgroup/angular-baselib";
import { ShopItemsService } from "./shopitems.service";
import { TranslateService } from "@ngx-translate/core";
import { CategoryType, getCategoryI18N, ShopItem, CategoryTypeArray } from "../../api-models/ShopItem";

@Component({
    selector: "app-shopitemdetail",
    templateUrl: "./shopitemdetail.component.html",
    styleUrls: ["shopitemdetail.component.scss"]
})
export class ShopItemDetailComponent extends BaseComponent implements OnInit {
    private shopItemId: string = null;
    public shopItem: ShopItem = null;

    public categories = CategoryTypeArray.map((categoryType: CategoryType) => {
        return {
            label: this.translateService.instant(getCategoryI18N(categoryType)),
            value: categoryType
        };
    });

    constructor(private route: ActivatedRoute, private shopItemsService: ShopItemsService, private router: Router, private alertService: AlertService, private translateService: TranslateService, private _location: Location) {
        super();

        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.shopItemId = params.id;
                if (this.shopItemId) {
                    this.initEditMode();
                } else {
                    this.initAddMode();
                }
            })
        );
    }

    ngOnInit(): void {}

    initAddMode() {
        this.MODE = BaseComponentMode.ADD;
        this.shopItem = new ShopItem();
    }

    initEditMode() {
        this.MODE = BaseComponentMode.EDIT;

        this.subscriptions.push(
            this.shopItemsService.shopItemDetail(this.shopItemId).subscribe(
                (result) => {
                    this.shopItem = result;
                },
                (err) => {
                    this.router.navigate(["../"], { relativeTo: this.route });
                }
            )
        );
    }

    save() {
        if (!this.shopItem.category) {
            return;
        }

        if (this.MODE === BaseComponentMode.ADD) {
            this.subscriptions.push(
                this.shopItemsService.addShopItem(this.shopItem).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("shopitems.detail.add.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }

        if (this.MODE === BaseComponentMode.EDIT) {
            this.subscriptions.push(
                this.shopItemsService.updateShopItem(this.shopItem).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("shopitems.detail.edit.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }
    }

    back() {
        this._location.back();
    }
}
