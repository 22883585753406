import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { IApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { plainToClass } from "class-transformer";
import { DateParseMode, VisitReportListResponseDTO, VisitReportMadListResponseDTO } from "../../api-models/VisitReport";

export interface IVisitReportsConfigService {
    visitReportsAPI: string;
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const VisitReportsConfigService = new InjectionToken<IVisitReportsConfigService>("VisitReportsConfig");

@Injectable()
export class VisitReportsService {
    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(VisitReportsConfigService) private visitReportsConfig: IVisitReportsConfigService, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.UTCDATEFORMAT = this.visitReportsConfig.utcDateFormat;
        this.datatableStateSaveMode = this.visitReportsConfig.datatableStateSaveMode;
    }

    public list({
        params,
        mode,
        mads,
        from,
        to
    }: {
        params: IApiListQueryParameter;
        mode?: DateParseMode;
        mads?: string[];
        from?: string;
        to?: string;
    }): Observable<{ list: VisitReportListResponseDTO[]; count: number; total: number; totalChecklistCount: number }> {
        let queryParams: {} = {
            ...params
        };

        if (mode) {
            queryParams = {
                ...queryParams,
                mode
            };
        }

        if (mads) {
            queryParams = {
                ...queryParams,
                mads: JSON.stringify(mads)
            };
        }

        if (from) {
            queryParams = {
                ...queryParams,
                from
            };
        }

        if (to) {
            queryParams = {
                ...queryParams,
                to
            };
        }

        return this.http
            .get<any>(this.getServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: queryParams,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    let count = result.body.list.length;
                    let total = result.body.list.length;
                    let totalChecklistCount = result.body.totalChecklistCount;

                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count"), 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count"), 10);
                    } catch (e) {}

                    return {
                        list: plainToClass(VisitReportListResponseDTO, result.body.list as Object[]),
                        count: count,
                        total: total,
                        totalChecklistCount: totalChecklistCount
                    };
                })
            );
    }

    public getMADs(): Observable<VisitReportMadListResponseDTO[]> {
        return this.http
            .get<Object[]>(this.getServiceUrl("mads"), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(VisitReportMadListResponseDTO, result)));
    }

    private getServiceUrl(postfix: string): string {
        return this.visitReportsConfig.visitReportsAPI + postfix;
    }
}
