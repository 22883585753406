<div class="content__inner">
    <header class="content__title">
        <h1>{{ "postcodeareas.list.title" | translate }}</h1>
        <small>{{ "postcodeareas.list.subtitle" | translate }}</small>

        <div class="actions">
            <button *appHasPermission="'POSTCODEAREAS.ADD'" (click)="addPostcodeArea()" class="btn btn-success btn--icon-text" style="margin-left: 25px">
                <i class="zmdi zmdi-collection-plus"></i> {{ "postcodeareas.list.add" | translate }}
            </button>
        </div>
    </header>

    <div class="card" *appHasPermission="'POSTCODEAREAS.LIST'">
        <div class="card-body">
            <h4 class="card-title">{{ "postcodeareas.list.card.title" | translate }}</h4>

            <table datatable class="table table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th id="name">{{ "postcodeareas.props.postcodeStart" | translate }}</th>
                        <th id="link">{{ "postcodeareas.props.postcodeEnd" | translate }}</th>
                        <th id="created">{{ "postcodeareas.props.created" | translate }}</th>
                        <th id="updated">{{ "postcodeareas.props.updated" | translate }}</th>
                        <th id="actions"></th>
                    </tr>
                </thead>
                <tbody *ngIf="rows?.length != 0">
                    <tr *ngFor="let row of rows">
                        <td>{{ row.postcodeStart }}</td>
                        <td>{{ row.postcodeEnd }}</td>
                        <td>{{ row.created | date: UTCDATEFORMAT:"+0" }}</td>
                        <td>{{ row.updated | date: UTCDATEFORMAT:"+0" }}</td>
                        <td class="text-right nowrap">
                            <i (click)="editPostcodeArea(row._id)" class="zmdi zmdi-edit zmdi-hc-fw tableIcon" *appHasPermission="'POSTCODEAREAS.EDIT'"></i>
                            <i (click)="deletePostcodeArea(row._id)" class="zmdi zmdi-delete zmdi-hc-fw tableIcon" *appHasPermission="'POSTCODEAREAS.DELETE'"></i>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="rows?.length == 0">
                    <tr>
                        <td colspan="6" class="no-data-available">{{ "global.datatable.empty" | translate }}</td>
                    </tr>
                </tbody>

                <tbody></tbody>
            </table>
        </div>
    </div>
</div>
