import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { OrderItemsService } from "./orderitems.service";
import { BaseDatatableComponent, IDataTableParameters } from "@impacgroup/angular-baselib";
import { OrderItem } from "../../api-models/OrderItem";
import { OrderItemsMainComponent } from "./orderitems.main.component";
import { saveAs } from "file-saver";
import * as moment from "moment";

@Component({
    selector: "app-orderitems",
    templateUrl: "./orderitems.component.html",
    styleUrls: ["orderitems.component.scss"]
})
export class OrderItemsComponent extends BaseDatatableComponent implements OnInit {
    public UTCDATEFORMAT: string = "";

    currentQueryParams: any = {};
    rows: OrderItem[] = [];

    startDate?: Date = undefined;
    endDate?: Date = undefined;

    dtInitialized = false;

    constructor(private orderItemsService: OrderItemsService, private translateService: TranslateService, parent: OrderItemsMainComponent) {
        super(translateService, {
            stateSaveMode: orderItemsService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_order_items_list"
        });

        const sortOrder = "asc";
        const sortByColumn = "created";

        const sortByColIndex = this._dataTableColums().findIndex((item) => item.data == sortByColumn);
        this.dtOptions.order = [[sortByColIndex != -1 ? sortByColIndex : 0, sortOrder]];
        this.UTCDATEFORMAT = this.orderItemsService.UTCDATEFORMAT;
    }

    ngOnInit() {}

    _dataTableTrigger() {
        return (dataTableParameters: IDataTableParameters, callback) => {
            // get api query parameters by datatable
            const queryParams: any = this.getApiQueryParamsByDataTable(dataTableParameters);
            queryParams.start = this.startDate?.toISOString();
            queryParams.end = this.endDate?.toISOString();

            this.currentQueryParams = queryParams;

            this.subscriptions.push(
                this.orderItemsService.orderItems(queryParams).subscribe((result) => {
                    this.rows = result.list;

                    callback({
                        recordsTotal: result.total,
                        recordsFiltered: result.count,
                        data: []
                    });
                })
            );
        };
    }

    _dataTableColums() {
        return [
            { data: "user", orderable: false },
            { data: "quantity", orderable: false },
            { data: "name", orderable: false },
            { data: "materialNumber", orderable: false },
            { data: "category", orderable: true },
            { data: "created", orderable: true },
            { data: "updated", orderable: true },
            { data: "actions", orderable: false }
        ];
    }

    filterChanged() {
        if (typeof this.dtElement !== "undefined" && typeof this.dtElement.dtInstance !== "undefined") {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.draw();
            });
        }
    }

    resetStartDate() {
        this.startDate = null;
    }

    resetEndDate() {
        this.endDate = null;
    }

    downloadOrderItemsCSV() {
        this.subscriptions.push(
            this.orderItemsService.downloadOrderItemsCSV(this.currentQueryParams).subscribe((orderItemsCSVBlob) => {
                let suffix = "";
                if (this.startDate && this.endDate) {
                    const startDate = moment(this.startDate).format("YYYY-MM-DDTHH-mm");
                    const endDate = moment(this.endDate).format("YYYY-MM-DDTHH-mm");
                    suffix = `_${startDate}_${endDate}`;
                } else {
                    if (this.startDate) {
                        const startDate = moment(this.startDate).format("YYYY-MM-DDTHH-mm");
                        suffix = `_${startDate}+`;
                    }

                    if (this.endDate) {
                        const endDate = moment(this.endDate).format("YYYY-MM-DDTHH-mm");
                        suffix = `_-${endDate}`;
                    }
                }

                const prefix = this.currentQueryParams.search ? "filtered_" : "";
                const fileName = `${prefix}orderitems${suffix}.csv`;
                saveAs(orderItemsCSVBlob, fileName);
            })
        );
    }
}
