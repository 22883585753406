import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ConfirmDialogComponent } from "@impacgroup/angular-baselib";
import { BsModalRef } from "ngx-bootstrap/modal";
import { TranslateService } from "@ngx-translate/core";
import { EventEmitter } from "@angular/core";
import * as moment from "moment";
import { TourDialogModel } from "../api-models/Tour";

@Component({
    selector: "app-tour-distribution-dialog",
    templateUrl: "./tourdistribution-dialog.component.html"
})
export class TourDistributionDialogComponent extends ConfirmDialogComponent implements OnInit, AfterViewInit {
    public isCreationFormActive: boolean = false;
    fromDateValue: string = "";
    toDateValue: string = "";
    tourNameValue: string = "";
    selectorOptions = [];
    selected: string;
    isSubmitted: boolean = false;
    public isProcessing: boolean = false;
    public isNoValidTour: boolean = false;
    created: EventEmitter<TourDialogModel> = new EventEmitter();
    confirmed: EventEmitter<string> = new EventEmitter();

    constructor(public bsModalRef: BsModalRef, private translateService: TranslateService) {
        super(bsModalRef);
    }

    ngOnInit() {
        this.fromDateValue = moment(Date.now()).format("YYYY-MM-DD");
        this.toDateValue = moment(this.fromDateValue).endOf("month").format("YYYY-MM-DD");
    }

    ngAfterViewInit(): void {
        if (this.selectorOptions.length < 1) {
            this.isCreationFormActive = true;
            this.isNoValidTour = true;
            return;
        } else {
            this.selected = this.selectorOptions[0]._id;
        }
    }

    showCreateTourForm() {
        this.isCreationFormActive = true;
    }

    onSaveBtn(valid: boolean) {
        if (this.isCreationFormActive) {
            if (!valid) {
                return;
            }
            this.createTour();
        } else {
            this.isProcessing = true;
            this.confirmed.emit(this.selected);
        }
    }

    createTour() {
        const tour: TourDialogModel = {
            name: this.tourNameValue,
            fromDate: moment(this.fromDateValue).toDate(),
            toDate: moment(this.toDateValue).toDate()
        };
        this.isProcessing = true;
        this.created.emit(tour);
    }
}
