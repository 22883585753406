<div class="content__inner">
    <header class="content__title">
        <h1 *ngIf="MODE === 'ADD'">{{ "tours.detail.add.title" | translate }}</h1>
        <h1 *ngIf="MODE === 'EDIT'">{{ "tours.detail.edit.title" | translate }}</h1>

        <div class="actions">
            <button (click)="back()" class="btn btn-secondary btn--icon-text"><i class="zmdi zmdi-arrow-back"></i> {{ "global.buttons.back" | translate }}</button>
        </div>
    </header>

    <div class="card">
        <div class="card-body">
            <form name="form" class="row" *ngIf="tour" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{ "tours.props.name" | translate }}</label>
                        <input name="name" type="text" class="form-control" [(ngModel)]="tour.name" #name="ngModel" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "tours.detail.validation.name" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="form-group">
                        <label>{{ "tours.props.fromdate" | translate }}</label>

                        <input
                            name="fromDate"
                            type="text"
                            class="form-control"
                            bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showMeridian: false, containerClass: 'theme-blue' }"
                            [(ngModel)]="tour.fromDate"
                            #fromDate="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && fromDate.invalid }"
                            required
                        />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "tours.detail.validation.fromdate" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="form-group">
                        <label>{{ "tours.props.todate" | translate }}</label>

                        <input
                            name="toDate"
                            type="text"
                            class="form-control"
                            bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showMeridian: false, containerClass: 'theme-blue' }"
                            [(ngModel)]="tour.toDate"
                            #toDate="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && toDate.invalid }"
                            required
                        />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "tours.detail.validation.todate" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <button class="float-right btn btn-success btn--icon-text" [disabled]="f.submitted && f.invalid"><i class="zmdi zmdi-check"></i> {{ "global.buttons.save" | translate }}</button>
                </div>
            </form>
        </div>
    </div>

    <div class="card" *ngIf="MODE === 'EDIT'">
        <div class="card-body">
            <div class="row">
                <div class="col-md-2">
                    <div class="form-group">
                        <label>{{ "distributors.overview.filter.status.title" | translate }}</label>
                        <div class="select">
                            <select class="form-control" #statusSelect [(ngModel)]="isStatus" (change)="onStatusSelected()">
                                <option [value]="undefined">{{ "distributors.overview.filter.status.options.all" | translate }}</option>
                                <option *ngFor="let statusOption of statusOptions" [value]="statusOption">{{ "distributors.overview.filter.status.options." + statusOption | translate }}</option>
                            </select>
                            <i class="form-group__bar"></i>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label>{{ "distributors.overview.filter.lifecycleStatus.title" | translate }}</label>
                        <div class="select">
                            <select class="form-control" #lifecycleStatusSelect [(ngModel)]="selectedLifecycleStatusFilter" (change)="onChangeFilter()">
                                <option [value]="undefined">{{ "distributors.overview.filter.lifecycleStatus.options.all" | translate }}</option>
                                <option *ngFor="let lifecycleStatusFilterItem of lifecycleStatusFilterItems" [value]="lifecycleStatusFilterItem">{{ lifecycleStatusFilterItem }}</option>
                            </select>
                            <i class="form-group__bar"></i>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ "distributors.overview.filter.salesline.title" | translate }}</label>
                        <input class="form-control" placeholder="{{ 'distributors.overview.filter.salesline.title' | translate }}" type="text" [(ngModel)]="saleslineFilter" (keyup)="saleslineChanged()" /><i class="form-group__bar"></i>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <div class="form-group">
                        <label>{{ "distributors.overview.filter.postcode.from" | translate }}</label>
                        <input
                            class="form-control"
                            placeholder="{{ 'distributors.overview.filter.postcode.title' | translate }}"
                            type="text"
                            [(ngModel)]="fromPostCode"
                            (keyup)="postCodeChanged(fromPostCode)"
                            [pattern]="numbersOnlyPattern"
                        /><i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="form-group">
                        <label>{{ "distributors.overview.filter.postcode.to" | translate }}</label>
                        <input class="form-control" placeholder="{{ 'distributors.overview.filter.postcode.title' | translate }}" type="text" [(ngModel)]="toPostCode" (keyup)="postCodeChanged(toPostCode)" [pattern]="numbersOnlyPattern" /><i
                            class="form-group__bar"
                        ></i>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ "distributors.overview.filter.city.title" | translate }}</label>
                        <input class="form-control" placeholder="{{ 'distributors.overview.filter.city.title' | translate }}" type="text" [(ngModel)]="city" (keyup)="cityChanged()" /><i class="form-group__bar"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card" *ngIf="MODE === 'EDIT' && tourDistributors?.length > 0">
        <div class="card-body">
            <h4 class="card-title">{{ "tours.detail.tourDistributors.title" | translate }}</h4>
            <div class="actions">
                <button class="float-right btn btn-outline-secondary btn--icon-text" (click)="csvExport()"><i class="zmdi zmdi-download"></i> {{ "tours.detail.tourDistributors.export" | translate }}</button>
            </div>

            <table class="table mb-0">
                <thead>
                    <tr>
                        <th id="selected"></th>
                        <th id="vo">{{ "tours.detail.tourDistributors.props.vo" | translate }}</th>
                        <th id="name">{{ "tours.detail.tourDistributors.props.name" | translate }}</th>
                        <th id="status">{{ "tours.detail.tourDistributors.props.status" | translate }}</th>
                        <th id="lifecycleStatus">{{ "tours.detail.tourDistributors.props.lifecycleStatus" | translate }}</th>
                        <th id="salesline">{{ "tours.detail.tourDistributors.props.salesline" | translate }}</th>
                        <th id="checklistLastCreated">{{ "tours.detail.tourDistributors.props.checklistLastCreated" | translate }}</th>
                        <th id="checklistLastCreatedBy">{{ "tours.detail.tourDistributors.props.checklistLastCreatedBy" | translate }}</th>
                        <th id="visited" colspan="2">{{ "tours.detail.tourDistributors.props.visited" | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let tourDistributor of tourDistributors">
                        <td>
                            <input type="checkbox" id="selected_{{ tourDistributor._id }}" [(ngModel)]="selectedRows[tourDistributor._id].selected" (change)="onSelectionToggle()" />
                        </td>
                        <td
                            class="vo"
                            [attr.role]="tourDistributor.visited && tourDistributor.checklistId ? 'button' : undefined"
                            (click)="tourDistributor.visited && tourDistributor.checklistId && showChecklistDetail(tourDistributor.checklistId)"
                        >
                            {{ tourDistributor.distributor.vo }}
                        </td>
                        <td
                            class="name"
                            [attr.role]="tourDistributor.visited && tourDistributor.checklistId ? 'button' : undefined"
                            (click)="tourDistributor.visited && tourDistributor.checklistId && showChecklistDetail(tourDistributor.checklistId)"
                        >
                            {{ tourDistributor.distributor.name }}<br />
                            {{ tourDistributor.distributor.street }}<br />
                            {{ tourDistributor.distributor.postcode }} {{ tourDistributor.distributor.city }}
                        </td>

                        <td
                            class="status"
                            [attr.role]="tourDistributor.visited && tourDistributor.checklistId ? 'button' : undefined"
                            (click)="tourDistributor.visited && tourDistributor.checklistId && showChecklistDetail(tourDistributor.checklistId)"
                        >
                            {{ tourDistributor.distributor.status }}
                        </td>
                        <td
                            class="lifecycleStatus"
                            [attr.role]="tourDistributor.visited && tourDistributor.checklistId ? 'button' : undefined"
                            (click)="tourDistributor.visited && tourDistributor.checklistId && showChecklistDetail(tourDistributor.checklistId)"
                        >
                            {{ tourDistributor.distributor.lifecycleStatus }}
                        </td>
                        <td
                            class="salesline"
                            [attr.role]="tourDistributor.visited && tourDistributor.checklistId ? 'button' : undefined"
                            (click)="tourDistributor.visited && tourDistributor.checklistId && showChecklistDetail(tourDistributor.checklistId)"
                        >
                            {{ tourDistributor.distributor.salesline }}
                        </td>
                        <td
                            class="checklistLastCreated"
                            [attr.role]="tourDistributor.visited && tourDistributor.checklistId ? 'button' : undefined"
                            (click)="tourDistributor.visited && tourDistributor.checklistId && showChecklistDetail(tourDistributor.checklistId)"
                            *ngIf="!tourDistributor.distributor.checklistLastCreatedHideVisitedDate"
                        >
                            {{ tourDistributor.distributor.checklistLastCreated | date : UTCDATEFORMAT }}
                        </td>
                        <td
                            class="checklistLastCreated"
                            [attr.role]="tourDistributor.visited && tourDistributor.checklistId ? 'button' : undefined"
                            (click)="tourDistributor.visited && tourDistributor.checklistId && showChecklistDetail(tourDistributor.checklistId)"
                            *ngIf="tourDistributor.distributor.checklistLastCreatedHideVisitedDate"
                        >
                            {{ tourDistributor.distributor.checklistLastCreated | date : "MM/yyyy" }}
                        </td>
                        <td
                            class="checklistLastCreatedBy"
                            [attr.role]="tourDistributor.visited && tourDistributor.checklistId ? 'button' : undefined"
                            (click)="tourDistributor.visited && tourDistributor.checklistId && showChecklistDetail(tourDistributor.checklistId)"
                        >
                            {{ tourDistributor.distributor.formatChecklistLastCreatedBy() }}
                        </td>
                        <td>
                            <div class="checkbox">
                                <input type="checkbox" id="checked_{{ tourDistributor._id }}" [checked]="tourDistributor.visited" (change)="preventChangeVisitedCheckbox($event, tourDistributor)" />
                                <label class="checkbox__label" for="checked_{{ tourDistributor._id }}"></label>
                            </div>
                        </td>

                        <td class="text-right nowrap">
                            <ng-container *ngIf="!tourDistributor.visited">
                                <i (click)="deleteTourDistributor(tourDistributor._id)" class="zmdi zmdi-delete zmdi-hc-fw tableIcon" *appHasPermission="'TOURS.EDIT'"></i>
                            </ng-container>
                            <ng-container *ngIf="tourDistributor.visited && tourDistributor.checklistId">
                                <i
                                    (click)="showChecklistDetail(tourDistributor.checklistId)"
                                    class="zmdi zmdi-collection-text zmdi-hc-fw tableIcon"
                                    *appHasPermission="'CHECKLIST.READ'"
                                    [tooltip]="'distributors.buttontooltips.downloadPDF' | translate"
                                    placement="bottom"
                                ></i>
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="col-12 mt-5">
                <span>
                    <button type="button" (click)="selectAll()" class="btn btn-success btn--icon-text mr-2 mb-2" [disabled]="selectedCopyTotal === tourDistributors.length">
                        <i class="zmdi zmdi-check"></i> {{ "tours.detail.tourDistributors.buttons.selectAll" | translate }}
                    </button>
                    <button type="button" (click)="deselectAll()" class="btn btn-success btn--icon-text mr-2 mb-2" [disabled]="selectedCopyTotal === 0">
                        <i class="zmdi zmdi-check"></i> {{ "tours.detail.tourDistributors.buttons.deselectAll" | translate }}
                    </button>

                    <button
                        [popoverDisabled]="true"
                        [popover]="selectedCopyTotal === selectedMoveTotal ? null : moveWarningNote"
                        placement="bottom"
                        triggers="mouseenter:mouseleave"
                        type="button"
                        (click)="showMoveTourDistributionDialog()"
                        class="btn btn-success btn--icon-text float-right ml-2 mb-2"
                        [disabled]="selectedMoveTotal === 0"
                    >
                        <i class="zmdi zmdi-check"></i> {{ "tours.detail.tourDistributors.buttons.moveSelectionToTour" | translate }} {{ "(" + selectedMoveTotal + ")" }}
                    </button>
                    <button type="button" (click)="showCopyTourDistributionDialog()" class="btn btn-success btn--icon-text float-right ml-2 mb-2" [disabled]="selectedCopyTotal === 0">
                        <i class="zmdi zmdi-check"></i> {{ "tours.detail.tourDistributors.buttons.copySelectionToTour" | translate }} {{ "(" + selectedCopyTotal + ")" }}
                    </button>
                </span>
            </div>
        </div>
    </div>
</div>

<ng-template #moveWarningNote>
    <b class="text-danger">{{ "tours.detail.tourDistributors.moveSelectionToTourNote" | translate }}</b>
</ng-template>
