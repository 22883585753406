<div class="content__inner">
    <header class="content__title">
        <h1 *ngIf="MODE === 'ADD'">{{ "postcodeareas.detail.add.title" | translate }}</h1>
        <h1 *ngIf="MODE === 'EDIT'">{{ "postcodeareas.detail.edit.title" | translate }}</h1>

        <div class="actions">
            <button (click)="back()" class="btn btn-secondary btn--icon-text"><i class="zmdi zmdi-arrow-back"></i> {{ "global.buttons.back" | translate }}</button>
        </div>
    </header>

    <div class="card">
        <div class="card-body">
            <form name="form" class="row" *ngIf="postcodeArea" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{ "postcodeareas.props.postcodeStart" | translate }}</label>
                        <input
                            name="postcodeStart"
                            type="text"
                            class="form-control"
                            [(ngModel)]="postcodeArea.postcodeStart"
                            #postcodeStart="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && postcodeStart.invalid }"
                            appValidDeZipCode
                            required
                        />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "postcodeareas.detail.validation.postcodeStart" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{ "postcodeareas.props.postcodeEnd" | translate }}</label>
                        <input name="postcodeEnd" type="text" class="form-control" [(ngModel)]="postcodeArea.postcodeEnd" #postcodeEnd="ngModel" [ngClass]="{ 'is-invalid': f.submitted && postcodeEnd.invalid }" appValidDeZipCode required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "postcodeareas.detail.validation.postcodeEnd" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <button class="float-right btn btn-success btn--icon-text" [disabled]="f.submitted && f.invalid"><i class="zmdi zmdi-check"></i> {{ "global.buttons.save" | translate }}</button>
                </div>
            </form>
        </div>
    </div>

    <div class="card" *ngIf="MODE === 'EDIT' && users.length > 0">
        <div class="card-body">
            <h4 class="card-title">{{ "postcodeareas.detail.availableUsers.title" | translate }}</h4>

            <table class="table mb-0">
                <thead>
                    <tr>
                        <th></th>
                        <th>{{ "postcodeareas.detail.availableUsers.props.firstname" | translate }}</th>
                        <th>{{ "postcodeareas.detail.availableUsers.props.lastname" | translate }}</th>
                        <th>{{ "postcodeareas.detail.availableUsers.props.email" | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of users">
                        <td>
                            <div class="checkbox">
                                <input type="checkbox" id="checked_{{ user._id }}" [checked]="user.checked" (change)="changeUserCheckbox($event, user)" />
                                <label class="checkbox__label" for="checked_{{ user._id }}"></label>
                            </div>
                        </td>
                        <td>{{ user?.firstname }}</td>
                        <td>{{ user?.lastname }}</td>
                        <td>{{ user?.email }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
