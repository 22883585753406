import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { FlyerImagesService } from "./flyerimages.service";
import { AlertService, BaseDatatableComponent, ConfirmDialogComponent, IDataTableParameters } from "@impacgroup/angular-baselib";
import { FlyerImage } from "../../api-models/FlyerImage";
import { FlyerImagesMainComponent } from "./flyerimages.main.component";

@Component({
    selector: "app-flyerimages",
    templateUrl: "./flyerimages.component.html",
    styleUrls: ["flyerimages.component.scss"]
})
export class FlyerImagesComponent extends BaseDatatableComponent implements OnInit {
    public UTCDATEFORMAT: string = "";

    rows: FlyerImage[] = [];

    constructor(
        private flyerImagesService: FlyerImagesService,
        private translateService: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private alertService: AlertService,
        parent: FlyerImagesMainComponent
    ) {
        super(translateService, {
            stateSaveMode: flyerImagesService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_flyerimages_list"
        });

        this.dtOptions.order = [[2, "asc"]];
        this.UTCDATEFORMAT = this.flyerImagesService.UTCDATEFORMAT;
    }

    ngOnInit() {}

    _dataTableTrigger() {
        return (dataTableParameters: IDataTableParameters, callback) => {
            // get api query parameters by datatable
            const queryParams = this.getApiQueryParamsByDataTable(dataTableParameters);

            this.subscriptions.push(
                this.flyerImagesService.flyerImages(queryParams).subscribe((result) => {
                    this.rows = result.list;

                    callback({
                        recordsTotal: result.total,
                        recordsFiltered: result.count,
                        data: []
                    });
                })
            );
        };
    }

    _dataTableColums() {
        return [
            { data: "_id", orderable: false },
            { data: "description", orderable: true },
            { data: "filename", orderable: true },
            { data: "created", orderable: true },
            { data: "updated", orderable: true },
            { data: "actions", orderable: false }
        ];
    }

    addFlyerImage() {
        this.router.navigate(["add"], { relativeTo: this.route });
    }

    editFlyerImage(id: string) {
        this.router.navigate([id], { relativeTo: this.route });
    }

    deleteFlyerImage(id: string) {
        const initialState = {
            title: this.translateService.instant("flyerimages.list.delete.title"),
            message: this.translateService.instant("flyerimages.list.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            this.subscriptions.push(
                this.flyerImagesService.deleteFlyerImage(id).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("flyerimages.list.delete.success"));
                    this.rerenderDatatable();
                })
            );
        };
    }
}
