<div class="content__inner">
    <header class="content__title">
        <h1 *ngIf="MODE === 'EDIT'">{{ "checklists.detail.edit.title" | translate }}</h1>

        <div class="actions">
            <button (click)="back()" class="btn btn-secondary btn--icon-text"><i class="zmdi zmdi-arrow-back"></i> {{ "global.buttons.back" | translate }}</button>
        </div>
    </header>

    <div class="card">
        <div class="card-body">
            <form name="form" class="row" *ngIf="checklist" #f="ngForm" novalidate>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label>{{ "checklists.props.distributor" | translate }}</label>

                        <div>{{ checklist.distributorObj.name }}</div>
                        <div>{{ checklist.distributorObj.street }}</div>
                        <div>{{ checklist.distributorObj.postcode }} {{ checklist.distributorObj.city }}</div>
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label>{{ "checklists.props.user" | translate }}</label>

                        <div>{{ checklist.userObj.firstname }}</div>
                        <div>{{ checklist.userObj.lastname }}</div>
                        <div>{{ checklist.userObj.email }}</div>
                    </div>
                </div>

                <div class="col-md-12 checklist-pdf-container mb-4">
                    <div class="checklist-pdf-preview">
                        <iframe *ngIf="checklistPDF" [src]="checklistPDF"></iframe>
                    </div>
                </div>

                <div class="col-12">
                    <span *appHasPermission="'CHECKLIST.UPDATE.CONFIRM'">
                        <button *ngIf="!checklist.approvalConfirmed" type="button" (click)="save('confirm')" class="btn btn-success btn--icon-text float-right ml-2 mb-2" [disabled]="f.submitted && f.invalid">
                            <i class="zmdi zmdi-check-all"></i> {{ "checklists.buttons.confirm" | translate }}
                        </button>
                        <button *ngIf="checklist.approvalConfirmed" type="button" (click)="save('unconfirm')" class="btn btn-danger btn--icon-text float-right ml-2 mb-2" [disabled]="f.submitted && f.invalid">
                            <i class="zmdi zmdi-check-all"></i> {{ "checklists.buttons.unconfirm" | translate }}
                        </button>
                    </span>

                    <span *appHasPermission="'CHECKLIST.UPDATE.APPROVE'">
                        <button *ngIf="!checklist.approved" type="button" (click)="save('approve')" class="btn btn-success btn--icon-text float-right ml-2 mb-2" [disabled]="f.submitted && f.invalid">
                            <i class="zmdi zmdi-check"></i> {{ "checklists.buttons.approve" | translate }}
                        </button>
                        <button *ngIf="checklist.approved" type="button" (click)="save('unapprove')" class="btn btn-danger btn--icon-text float-right ml-2 mb-2" [disabled]="f.submitted && f.invalid">
                            <i class="zmdi zmdi-check"></i> {{ "checklists.buttons.unapprove" | translate }}
                        </button>
                    </span>

                    <span *appHasPermission="'CHECKLIST.UPDATE.MARK'">
                        <button *ngIf="!checklist.marked" type="button" (click)="save('mark')" class="btn btn-success btn--icon-text float-right ml-2 mb-2" [disabled]="f.submitted && f.invalid">
                            <i class="zmdi zmdi-bookmark"></i> {{ "checklists.buttons.mark" | translate }}
                        </button>
                        <button *ngIf="checklist.marked" type="button" (click)="save('unmark')" class="btn btn-danger btn--icon-text float-right ml-2 mb-2" [disabled]="f.submitted && f.invalid">
                            <i class="zmdi zmdi-bookmark-outline"></i> {{ "checklists.buttons.unmark" | translate }}
                        </button>
                    </span>

                    <button *appHasPermission="'CHECKLIST.READ'" type="button" (click)="downloadChecklist()" class="btn btn-success btn--icon-text float-right ml-2 mb-2" [disabled]="f.submitted && f.invalid">
                        <i class="zmdi zmdi-download"></i> {{ "checklists.buttons.downloadPDF" | translate }}
                    </button>

                    <button (click)="back()" class="btn btn-secondary btn--icon-text float-right ml-2 mb-2"><i class="zmdi zmdi-arrow-back"></i> {{ "global.buttons.back" | translate }}</button>
                </div>
            </form>
        </div>
    </div>
</div>
