import { Exclude, Type } from "class-transformer";
import { User, UserObj } from "./Checklist";
import { CategoryType } from "./ShopItem";

export class OrderItem {
    _id?: string;

    @Type(() => Order)
    order?: Order;
    user?: string;
    quantity: number;
    name: string;
    materialNumber: string;
    category: CategoryType;

    version: number;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    created: Date;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    updated: Date;
}

export class Order {
    user?: User;
    userObj?: UserObj;

    public formatUser(): string {
        if (typeof this.userObj === "undefined") {
            return "";
        }

        const user = this.userObj;

        return `${user.firstname ? `${user.firstname} ` : ""}${user.lastname ? `${user.lastname} ` : ""}${user.firstname || user.lastname ? "\r\n" : ""}${user.email ? `${user.email} ` : ""}${user.comment ? `\r\n\r\n${user.comment} ` : ""}`;
    }

    public htmlFormatUser(): string {
        return this.formatUser().replace(/(?:\r\n|\r|\n)/g, "<br />");
    }
}
