import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { map } from "rxjs/operators";
import { classToPlain, plainToClass } from "class-transformer";
import { ChecklistTemplate } from "../../api-models/ChecklistTemplate";

export interface IChecklistTemplateConfigService {
    checklistTemplateAPI: string;
    utcDateFormat: string;
}

export const ChecklistTemplateConfigService = new InjectionToken<IChecklistTemplateConfigService>("ChecklistTemplateConfig");

@Injectable()
export class ChecklistTemplateService {
    public UTCDATEFORMAT: string = "";

    constructor(@Inject(ChecklistTemplateConfigService) private checklistTemplateConfig: IChecklistTemplateConfigService, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.UTCDATEFORMAT = this.checklistTemplateConfig.utcDateFormat;
    }

    public getChecklistTemplate(): Observable<ChecklistTemplate> {
        return this.http
            .get<Object>(this.getChecklistTemplateServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(ChecklistTemplate, result)));
    }

    public updateChecklistTemplate(checklistTemplate: ChecklistTemplate): Observable<ChecklistTemplate> {
        return this.http
            .patch<Object>(this.getChecklistTemplateServiceUrl(""), classToPlain(checklistTemplate), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(ChecklistTemplate, result)));
    }

    private getChecklistTemplateServiceUrl(postfix: string): string {
        return this.checklistTemplateConfig.checklistTemplateAPI + postfix;
    }
}
