import { Type } from "class-transformer";

export class OAuthUserListOutputDTO {
    _id: string;
    firstname: string;
    lastname: string;
    email: string;
}

export class PostcodeAreaUserListOutputDTO {
    _id: string;

    @Type(() => OAuthUserListOutputDTO)
    user: OAuthUserListOutputDTO;

    version: number;

    @Type(() => Date)
    created: Date;

    @Type(() => Date)
    updated: Date;
}

export class PostcodeAreaUserCreateInputDTO {
    public user: string;
}

export class PostcodeAreaUser extends OAuthUserListOutputDTO {
    checked: boolean;

    static fromDTO(user: PostcodeAreaUserListOutputDTO) {
        const postcodeAreaUser = new PostcodeAreaUser();
        postcodeAreaUser._id = user.user._id;
        postcodeAreaUser.firstname = user.user.firstname;
        postcodeAreaUser.lastname = user.user.lastname;
        postcodeAreaUser.email = user.user.email;
        postcodeAreaUser.checked = true;

        return postcodeAreaUser;
    }

    static fromOAuthDTO(user: OAuthUserListOutputDTO) {
        const postcodeAreaUser = new PostcodeAreaUser();
        postcodeAreaUser._id = user._id;
        postcodeAreaUser.firstname = user.firstname;
        postcodeAreaUser.lastname = user.lastname;
        postcodeAreaUser.email = user.email;
        postcodeAreaUser.checked = false;

        return postcodeAreaUser;
    }
}
