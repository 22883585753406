import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { IApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { classToPlain, plainToClass } from "class-transformer";
import { PostcodeArea } from "../../api-models/PostcodeArea";
import { OAuthUserListOutputDTO, PostcodeAreaUserCreateInputDTO, PostcodeAreaUserListOutputDTO } from "../../api-models/PostcodeAreaUser";

export interface IPostcodeAreaConfigService {
    postcodeAreasAPI: string;
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const PostcodeAreaConfigService = new InjectionToken<IPostcodeAreaConfigService>("PostcodeAreaConfig");

@Injectable()
export class PostcodeAreasService {
    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(PostcodeAreaConfigService) private postcodeAreaConfig: IPostcodeAreaConfigService, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.UTCDATEFORMAT = this.postcodeAreaConfig.utcDateFormat;
        this.datatableStateSaveMode = this.postcodeAreaConfig.datatableStateSaveMode;
    }

    public postcodeAreas(params: IApiListQueryParameter): Observable<{ list: PostcodeArea[]; count: number; total: number }> {
        return this.http
            .get<any>(this.getPostcodeAreasServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: params as any,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    let count = result.body.length;
                    let total = result.body.length;
                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count"), 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count"), 10);
                    } catch (e) {}

                    return {
                        list: plainToClass(PostcodeArea, result.body as any[]),
                        count: count,
                        total: total
                    };
                })
            );
    }

    public postcodeAreaDetail(id: string): Observable<PostcodeArea> {
        return this.http
            .get<Object>(this.getPostcodeAreasServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(PostcodeArea, result)));
    }

    public addPostcodeArea(postcodeArea: PostcodeArea): Observable<PostcodeArea> {
        return this.http
            .post<Object>(this.getPostcodeAreasServiceUrl(""), classToPlain(postcodeArea), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(PostcodeArea, result)));
    }

    public updatePostcodeArea(postcodeArea: PostcodeArea): Observable<PostcodeArea> {
        return this.http
            .patch<Object>(this.getPostcodeAreasServiceUrl(postcodeArea._id), classToPlain(postcodeArea), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(PostcodeArea, result)));
    }

    public deletePostcodeArea(id: string): Observable<PostcodeArea> {
        return this.http
            .delete<Object>(this.getPostcodeAreasServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(PostcodeArea, result)));
    }

    /**
     * PostcodeAreaUser stuff following below...
     */

    public getAvailablePostcodeUsers(id: string): Observable<OAuthUserListOutputDTO[]> {
        return this.http
            .get<Object[]>(this.getPostcodeAreasServiceUrl(`${id}/availableusers/`), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(OAuthUserListOutputDTO, result)));
    }

    public getAssignedPostcodeUsers(id: string): Observable<PostcodeAreaUserListOutputDTO[]> {
        return this.http
            .get<Object[]>(this.getPostcodeAreasServiceUrl(`${id}/users/`), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(PostcodeAreaUserListOutputDTO, result)));
    }

    public addPostcodeUser(id: string, userData: PostcodeAreaUserCreateInputDTO): Observable<Object> {
        return this.http.post<Object>(this.getPostcodeAreasServiceUrl(`${id}/users/`), classToPlain(userData), {
            headers: this.oauthStorageAdapter.getAuthHeadersJSON()
        });
    }

    public deletePostcodeUser(id: string, userId: string): Observable<Object> {
        return this.http.delete<Object[]>(this.getPostcodeAreasServiceUrl(`${id}/users/${userId}`), {
            headers: this.oauthStorageAdapter.getAuthHeadersJSON()
        });
    }

    private getPostcodeAreasServiceUrl(postfix: string): string {
        return this.postcodeAreaConfig.postcodeAreasAPI + postfix;
    }
}
