import { Expose, Type } from "class-transformer";
import { DistributorStatus } from "./Checklist";

export class User {
    firstname?: string;
    lastname?: string;
    email?: string;
    comment?: string;
}

export class Distributor {
    @Expose()
    _id?: string;

    @Expose()
    vo: string;

    @Expose()
    name: string;

    @Expose()
    street: string;

    @Expose()
    postcode: string;

    @Expose()
    city: string;

    @Expose()
    country: string;

    @Expose()
    disabled: boolean;

    @Expose()
    postcodeNumber: number;

    @Expose()
    checklistLastCreated: Date;

    @Expose()
    checklistLastCreatedByObj: User;

    @Expose()
    checklistLastCreatedHideVisitedDate: boolean;

    @Expose()
    status: DistributorStatus;

    @Expose()
    lifecycleStatus?: string;

    @Expose()
    salesline?: string;

    @Expose()
    public formatChecklistLastCreatedBy(): string {
        const user = this.checklistLastCreatedByObj;
        if (user) {
            return `${user.firstname ? `${user.firstname} ` : ""}${user.lastname ? `${user.lastname} ` : ""}`;
        } else {
            return "";
        }
    }
}

export class TourDistributorListModel {
    @Expose()
    _id?: string;

    @Type(() => Distributor)
    @Expose()
    distributor?: Distributor;

    @Expose()
    checklistId?: string;

    @Expose()
    visited?: Boolean;

    @Expose()
    version?: number;
}
