import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";

import { PostcodeAreasComponent } from "./postcodeareas.component";
import { PostcodeAreasService, PostcodeAreaConfigService, IPostcodeAreaConfigService } from "./postcodeareas.service";
import { PostcodeAreaDetailComponent } from "./postcodeareadetail.component";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { PostcodeAreasMainComponent } from "./postcodeareas.main.component";
import { ValidDeZipCodeModule } from "src/app/directive/valid-de-zip-code/valid-de-zip-code.module";

@NgModule({
    declarations: [PostcodeAreasMainComponent, PostcodeAreasComponent, PostcodeAreaDetailComponent],
    imports: [CommonModule, RouterModule, FormsModule, DataTablesModule, BsDropdownModule, ValidDeZipCodeModule, ModalModule, NgSelectModule, TranslateModule, OAuthModule],
    exports: [],
    providers: []
})
export class PostcodeAreasModule {
    static forRoot(postcodeAreaConfig: IPostcodeAreaConfigService): ModuleWithProviders<PostcodeAreasModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: PostcodeAreasModule,
            providers: [
                PostcodeAreasService,
                {
                    provide: PostcodeAreaConfigService,
                    useValue: postcodeAreaConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: PostcodeAreasMainComponent,
                children: [
                    {
                        path: "",
                        component: PostcodeAreasComponent
                    },
                    {
                        path: "add",
                        component: PostcodeAreaDetailComponent
                    },
                    {
                        path: ":id",
                        component: PostcodeAreaDetailComponent
                    }
                ]
            }
        ];
    }
}
