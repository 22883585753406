import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FullLayoutComponent } from "./layout/layouts/layout-full/layout.component";
import { SingleLayoutComponent } from "./layout/layouts/layout-single/layout.component";
import { AuthenticationGuard } from "@impacgroup/angular-oauth-base";
import { OrganizationsModule, RolesModule, UsersModule } from "@impacgroup/angular-access-management";
import { ProfileModule, ResetPasswordModule } from "@impacgroup/angular-selfservice";
import { LanguagesModule } from "@impacgroup/angular-languages";
import { MailTemplatesModule, SmtpMailAccountsModule } from "@impacgroup/angular-mailtemplates";
import { FirebaseConfigModule, FirebaseDevicesModule, FirebaseNotificationsModule, FirebaseNotificationTemplatesModule } from "@impacgroup/angular-firebase";
import { SystemLogMessagesModule, SystemJobPerformancesModule, SystemResourceSnapshotsModule, SystemErrorsModule } from "@impacgroup/angular-systemhealth";
import { PostcodeAreasModule } from "./modules/postcodeareas/postcodeareas.module";
import { FlyerImagesModule } from "./modules/flyerimages/flyerimages.module";
import { InterestedPartiesModule } from "./modules/interestedparties/interestedparties.module";
import { ChecklistTemplateModule } from "./modules/checklisttemplate/checklisttemplate.module";
import { DistributorsModule } from "./modules/distributors/distributors.module";
import { ChecklistsModule } from "./modules/checklists/checklists.module";
import { ShopItemsModule } from "./modules/shopitems/shopitems.module";
import { OrderItemsModule } from "./modules/orderitems/orderitems.module";
import { VisitReportsModule } from "./modules/visitreports/visitreports.module";
import { ToursModule } from "./modules/tours/tours.module";

const routes: Routes = [
    {
        path: "",
        component: FullLayoutComponent,
        canActivate: [AuthenticationGuard],
        canActivateChild: [AuthenticationGuard],
        children: [
            // Home
            {
                path: "",
                redirectTo: "home",
                pathMatch: "full"
            },
            {
                path: "home",
                loadChildren: () => import("./pages/home/home.module").then((m) => m.HomeModule)
            },
            {
                path: "usermanagement",
                children: [
                    {
                        path: "",
                        redirectTo: "users",
                        pathMatch: "full"
                    },
                    {
                        path: "users",
                        children: UsersModule.getRoutes()
                    },
                    {
                        path: "roles",
                        children: RolesModule.getRoutes()
                    },
                    {
                        path: "organizations",
                        children: OrganizationsModule.getRoutes()
                    }
                ]
            },
            {
                path: "profile",
                children: ProfileModule.getRoutes()
            },
            {
                path: "tours",
                children: ToursModule.getRoutes()
            },
            {
                path: "distributors",
                children: DistributorsModule.getRoutes()
            },
            {
                path: "visitreports",
                children: VisitReportsModule.getRoutes()
            },
            {
                path: "languages",
                children: LanguagesModule.getRoutes()
            },
            {
                path: "mail",
                children: [
                    {
                        path: "",
                        redirectTo: "mailaccounts",
                        pathMatch: "full"
                    },
                    {
                        path: "mailaccounts",
                        children: SmtpMailAccountsModule.getRoutes()
                    },
                    {
                        path: "mailtemplates",
                        children: MailTemplatesModule.getRoutes()
                    }
                ]
            },
            {
                path: "firebase",
                children: [
                    {
                        path: "",
                        redirectTo: "firebaseconfig",
                        pathMatch: "full"
                    },
                    {
                        path: "firebaseconfig",
                        children: FirebaseConfigModule.getRoutes()
                    },
                    {
                        path: "firebasedevices",
                        children: FirebaseDevicesModule.getRoutes()
                    },
                    {
                        path: "firebasenotifications",
                        children: FirebaseNotificationsModule.getRoutes()
                    },
                    {
                        path: "firebasenotificationtemplates",
                        children: FirebaseNotificationTemplatesModule.getRoutes()
                    }
                ]
            },
            {
                path: "postcodeareas",
                children: PostcodeAreasModule.getRoutes()
            },
            {
                path: "flyerimages",
                children: FlyerImagesModule.getRoutes()
            },
            {
                path: "interestedparties",
                children: InterestedPartiesModule.getRoutes()
            },
            {
                path: "checklists",
                children: ChecklistsModule.getRoutes()
            },
            {
                path: "checklisttemplate",
                children: ChecklistTemplateModule.getRoutes()
            },
            {
                path: "orderitems",
                children: OrderItemsModule.getRoutes()
            },
            {
                path: "shopitems",
                children: ShopItemsModule.getRoutes()
            },
            {
                path: "systemhealth",
                children: [
                    {
                        path: "",
                        redirectTo: "logs",
                        pathMatch: "full"
                    },
                    {
                        path: "logs",
                        children: SystemLogMessagesModule.getRoutes()
                    },
                    {
                        path: "jobs",
                        children: SystemJobPerformancesModule.getRoutes()
                    },
                    {
                        path: "resources",
                        children: SystemResourceSnapshotsModule.getRoutes()
                    },
                    {
                        path: "errors",
                        children: SystemErrorsModule.getRoutes()
                    }
                ]
            }
        ]
    },
    {
        path: "",
        component: SingleLayoutComponent,
        children: [
            {
                path: "login",
                loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginModule)
            },
            {
                path: "resetpassword",
                children: ResetPasswordModule.getRoutes()
            },
            {
                path: "404",
                loadChildren: () => import("./pages/four-zero-four/four-zero-four.module").then((m) => m.FourZeroFourModule)
            },
            {
                path: "**",
                redirectTo: "/404"
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: "legacy" })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
