import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { IApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { classToPlain, plainToClass } from "class-transformer";
import { FlyerImage } from "../../api-models/FlyerImage";

export interface IFlyerImageConfigService {
    flyerImagesAPI: string;
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const FlyerImageConfigService = new InjectionToken<IFlyerImageConfigService>("FlyerImageConfig");

@Injectable()
export class FlyerImagesService {
    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(FlyerImageConfigService) private flyerImageConfig: IFlyerImageConfigService, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.UTCDATEFORMAT = this.flyerImageConfig.utcDateFormat;
        this.datatableStateSaveMode = this.flyerImageConfig.datatableStateSaveMode;
    }

    public flyerImages(params: IApiListQueryParameter): Observable<{ list: FlyerImage[]; count: number; total: number }> {
        return this.http
            .get<any>(this.getFlyerImagesServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: params as any,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    let count = result.body.length;
                    let total = result.body.length;
                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count"), 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count"), 10);
                    } catch (e) {}

                    return {
                        list: plainToClass(FlyerImage, result.body as any[]),
                        count: count,
                        total: total
                    };
                })
            );
    }

    public flyerImageDetail(id: string): Observable<FlyerImage> {
        return this.http
            .get<Object>(this.getFlyerImagesServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(FlyerImage, result)));
    }

    public addFlyerImage(flyerImage: FlyerImage, flyerFile: File): Observable<FlyerImage> {
        var subject = new Subject<FlyerImage>();

        this.http
            .post<Object>(this.getFlyerImagesServiceUrl(""), classToPlain(flyerImage), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(FlyerImage, result)))
            .subscribe((resultFlyerImage: FlyerImage) => {
                const flyerImageId: String = resultFlyerImage._id;

                const formData: FormData = new FormData();
                formData.append("file", flyerFile, flyerFile.name);

                this.http
                    .post<void>(this.getFlyerImagesServiceUrl(flyerImageId + "/upload"), formData, {
                        headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType()
                    })
                    .subscribe((flyerImageFile: any) => {
                        subject.next(resultFlyerImage);
                    });
            });

        return subject.asObservable();
    }

    public updateFlyerImage(flyerImage: FlyerImage, flyerFile: File | null): Observable<void> {
        var subject = new Subject<void>();

        this.http
            .patch<Object>(this.getFlyerImagesServiceUrl(flyerImage._id), classToPlain(flyerImage), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(FlyerImage, result)))
            .subscribe((resultFlyerImage: FlyerImage) => {
                const flyerImageId: String = resultFlyerImage._id;

                if (flyerFile !== null) {
                    const formData: FormData = new FormData();
                    formData.append("file", flyerFile, flyerFile.name);

                    this.http
                        .post<void>(this.getFlyerImagesServiceUrl(flyerImageId + "/upload"), formData, {
                            headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType()
                        })
                        .subscribe((flyerImageFile: any) => {
                            subject.next();
                        });
                } else {
                    subject.next();
                }
            });

        return subject.asObservable();
    }

    public downloadFlyerImageFile(flyerImageId: String): Observable<Blob> {
        return this.http.get(this.getFlyerImagesServiceUrl(flyerImageId + "/download"), {
            headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
            responseType: "blob"
        });
    }

    public deleteFlyerImage(id: string): Observable<FlyerImage> {
        return this.http
            .delete<Object>(this.getFlyerImagesServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(FlyerImage, result)));
    }

    private getFlyerImagesServiceUrl(postfix: string): string {
        return this.flyerImageConfig.flyerImagesAPI + postfix;
    }
}
