<div class="content__inner">
    <header class="content__title">
        <h1>{{ "flyerimages.list.title" | translate }}</h1>
        <small>{{ "flyerimages.list.subtitle" | translate }}</small>

        <div class="actions">
            <button *appHasPermission="'FLYERIMAGES.ADD'" (click)="addFlyerImage()" class="btn btn-success btn--icon-text" style="margin-left: 25px"><i class="zmdi zmdi-collection-plus"></i> {{ "flyerimages.list.add" | translate }}</button>
        </div>
    </header>

    <div class="card" *appHasPermission="'FLYERIMAGES.LIST'">
        <div class="card-body">
            <h4 class="card-title">{{ "flyerimages.list.card.title" | translate }}</h4>

            <table datatable class="table table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th id="_id">{{ "flyerimages.props._id" | translate }}</th>
                        <th id="description">{{ "flyerimages.props.description" | translate }}</th>
                        <th id="filename">{{ "flyerimages.props.filename" | translate }}</th>
                        <th id="created">{{ "flyerimages.props.created" | translate }}</th>
                        <th id="updated">{{ "flyerimages.props.updated" | translate }}</th>
                        <th id="actions"></th>
                    </tr>
                </thead>
                <tbody *ngIf="rows?.length != 0">
                    <tr *ngFor="let row of rows">
                        <td>{{ row._id }}</td>
                        <td>{{ row.description }}</td>
                        <td>{{ row.filename }}</td>
                        <td>{{ row.created | date: UTCDATEFORMAT:"+0" }}</td>
                        <td>{{ row.updated | date: UTCDATEFORMAT:"+0" }}</td>
                        <td class="text-right nowrap">
                            <i (click)="editFlyerImage(row._id)" class="zmdi zmdi-edit zmdi-hc-fw tableIcon" *appHasPermission="'FLYERIMAGES.EDIT'"></i>
                            <i (click)="deleteFlyerImage(row._id)" class="zmdi zmdi-delete zmdi-hc-fw tableIcon" *appHasPermission="'FLYERIMAGES.DELETE'"></i>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="rows?.length == 0">
                    <tr>
                        <td colspan="6" class="no-data-available">{{ "global.datatable.empty" | translate }}</td>
                    </tr>
                </tbody>

                <tbody></tbody>
            </table>
        </div>
    </div>
</div>
