import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { IApiListQueryParameter } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { plainToClass } from "class-transformer";
import {
    AdminTourCreateRequestDTO,
    AdminTourDetailResponseDTO,
    AdminTourDistributorCreateRequestDTO,
    AdminTourDistributorCreateResponseDTO,
    AdminTourDistributorDetailResponseDTO,
    AdminTourDistributorListResponseDTO,
    AdminTourDistributorTransferRequestDTO,
    AdminTourDistributorTransferResponseDTO,
    AdminTourDistributorUpdateVisitedRequestDTO,
    AdminTourListResponseDTO,
    AdminTourUpdateRequestDTO
} from "@impacgroup/ayyildiz-checklist-platform-api-dtos";
import { AdminTourDuplicateRequestDTO, AdminTourDuplicateResponseDTO } from "./AdminTourApiService.dto";
import { DistributorStatus } from "src/app/api-models/Checklist";

export interface ITourRepositoryConfig {
    toursAPI: string;
}

export const TourRepositoryConfig = new InjectionToken<ITourRepositoryConfig>("TourRepositoryConfig");

@Injectable()
export class ToursRepository {
    constructor(@Inject(TourRepositoryConfig) private tourRepositoryConfig: ITourRepositoryConfig, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {}

    // --------------------- '/admin/tour' endpoint --------------------- //
    public list(params: IApiListQueryParameter, currentAndFutureOnly?: boolean): Observable<{ list: AdminTourListResponseDTO[]; count: number; total: number }> {
        let queryPramas: {} = {
            ...params
        };

        if (currentAndFutureOnly) {
            queryPramas = {
                ...queryPramas,
                currentAndFutureOnly
            };
        }

        return this.http
            .get<Object[]>(this.getServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: queryPramas,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    let count = result.body?.length ?? 0;
                    let total = result.body?.length ?? 0;

                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count") ?? "0", 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count") ?? "0", 10);
                    } catch (e) {}

                    return {
                        list: plainToClass(AdminTourListResponseDTO, result.body ?? [], { excludeExtraneousValues: true }),
                        count: count,
                        total: total
                    };
                })
            );
    }

    public create(dto: AdminTourCreateRequestDTO): Observable<AdminTourDetailResponseDTO> {
        return this.http
            .post<Object>(this.getServiceUrl(""), dto, {
                headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType()
            })
            .pipe(map((result) => plainToClass(AdminTourDetailResponseDTO, result, { excludeExtraneousValues: true })));
    }

    public read(id: string): Observable<AdminTourDetailResponseDTO> {
        return this.http
            .get<Object>(this.getServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(AdminTourDetailResponseDTO, result, { excludeExtraneousValues: true })));
    }

    public update(id: string, dto: AdminTourUpdateRequestDTO): Observable<AdminTourDetailResponseDTO> {
        return this.http
            .patch<Object>(this.getServiceUrl(id), dto, {
                headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType()
            })
            .pipe(map((result) => plainToClass(AdminTourDetailResponseDTO, result, { excludeExtraneousValues: true })));
    }

    public delete(id: string): Observable<AdminTourDetailResponseDTO> {
        return this.http
            .delete<Object>(this.getServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(AdminTourDetailResponseDTO, result, { excludeExtraneousValues: true })));
    }

    public duplicate(id: string, dto: AdminTourDuplicateRequestDTO): Observable<AdminTourDuplicateResponseDTO> {
        return this.http
            .post<Object>(this.getServiceUrl(`${id}/duplicate`), dto, {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(AdminTourDuplicateResponseDTO, result, { excludeExtraneousValues: true })));
    }

    // --------------------- '/admin/tours/:tourId/distributors' endpoint --------------------- //
    public listTourDistributors({
        tourId,
        params,
        status,
        fromPostCode,
        toPostCode,
        city,
        saleslineFilter,
        selectedLifecycleStatusFilter
    }: {
        tourId: string;
        params: IApiListQueryParameter;
        status?: DistributorStatus;
        fromPostCode?: string;
        toPostCode?: string;
        city?: string;
        saleslineFilter?: string;
        selectedLifecycleStatusFilter?: string;
    }): Observable<{ list: AdminTourDistributorListResponseDTO[]; count: number; total: number }> {
        let queryParams = params as any;

        if (status) {
            queryParams = {
                ...queryParams,
                status
            };
        }

        if (fromPostCode !== undefined) {
            queryParams = {
                ...queryParams,
                fromPostCode: fromPostCode
            };
        }

        if (toPostCode !== undefined) {
            queryParams = {
                ...queryParams,
                toPostCode: toPostCode
            };
        }

        if (city !== undefined) {
            queryParams = {
                ...queryParams,
                city: city
            };
        }

        if (saleslineFilter !== undefined) {
            queryParams = {
                ...queryParams,
                salesline: saleslineFilter
            };
        }

        if (selectedLifecycleStatusFilter !== undefined) {
            queryParams = {
                ...queryParams,
                lifecycleStatus: selectedLifecycleStatusFilter
            };
        }

        return this.http
            .get<Object[]>(this.getServiceUrl(`${tourId}/distributors`), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: queryParams,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    let count = result.body?.length ?? 0;
                    let total = result.body?.length ?? 0;

                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count") ?? "0", 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count") ?? "0", 10);
                    } catch (e) {}

                    return {
                        list: plainToClass(AdminTourDistributorListResponseDTO, result.body ?? [], { excludeExtraneousValues: true }),
                        count: count,
                        total: total
                    };
                })
            );
    }

    public csvExportTourDistributors({
        tourId,
        params,
        status,
        fromPostCode,
        toPostCode,
        city,
        saleslineFilter,
        selectedLifecycleStatusFilter
    }: {
        tourId: string;
        params: IApiListQueryParameter;
        status?: DistributorStatus;
        fromPostCode?: string;
        toPostCode?: string;
        city?: string;
        saleslineFilter?: string;
        selectedLifecycleStatusFilter?: string;
    }): Observable<Blob> {
        let queryParams = params as any;

        if (status) {
            queryParams = {
                ...queryParams,
                status
            };
        }

        if (fromPostCode !== undefined) {
            queryParams = {
                ...queryParams,
                fromPostCode: fromPostCode
            };
        }

        if (toPostCode !== undefined) {
            queryParams = {
                ...queryParams,
                toPostCode: toPostCode
            };
        }

        if (city !== undefined) {
            queryParams = {
                ...queryParams,
                city: city
            };
        }

        if (saleslineFilter !== undefined) {
            queryParams = {
                ...queryParams,
                salesline: saleslineFilter
            };
        }

        if (selectedLifecycleStatusFilter !== undefined) {
            queryParams = {
                ...queryParams,
                lifecycleStatus: selectedLifecycleStatusFilter
            };
        }

        return this.http.get(this.getServiceUrl(`${tourId}/distributors/csv`), {
            headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
            params: queryParams,
            responseType: "blob"
        });
    }

    public createTourDistributors(dto: AdminTourDistributorCreateRequestDTO, params: IApiListQueryParameter): Observable<AdminTourDistributorCreateResponseDTO> {
        return this.http
            .post<Object>(this.getServiceUrl(`${dto.tour}/distributors/`), dto, {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: { ...params }
            })
            .pipe(map((result) => plainToClass(AdminTourDistributorCreateResponseDTO, result, { excludeExtraneousValues: true })));
    }

    public deleteTourDistributor({ tourId, tourDistributorId }: { tourId: string; tourDistributorId: string }): Observable<Object> {
        return this.http.delete<Object>(this.getServiceUrl(`${tourId}/distributors/${tourDistributorId}`), {
            headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType()
        });
    }

    public setTourDistributorVisited({ tourId, tourDistributorId, dto }: { tourId: string; tourDistributorId: string; dto: AdminTourDistributorUpdateVisitedRequestDTO }): Observable<AdminTourDistributorDetailResponseDTO> {
        return this.http.patch<Object>(this.getServiceUrl(`${tourId}/distributors/${tourDistributorId}/visited`), dto, {
            headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType()
        });
    }

    public transferTourDistributors({ tourId, dto }: { tourId: string; dto: AdminTourDistributorTransferRequestDTO }): Observable<AdminTourDistributorTransferResponseDTO> {
        return this.http
            .post<Object>(this.getServiceUrl(`${tourId}/distributors/transfer`), dto, {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(AdminTourDistributorTransferResponseDTO, result, { excludeExtraneousValues: true })));
    }

    private getServiceUrl(postfix: string): string {
        return this.tourRepositoryConfig.toursAPI + postfix;
    }
}
