import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { IApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { plainToClass } from "class-transformer";
import { DistributorImportOverview } from "../../api-models/DistributorImportOverview";
import { Distributor, DistributorObj, DistributorStatus } from "src/app/api-models/Checklist";

export interface IDistributorConfigService {
    distributorsAPI: string;
    dateFormat: string;
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

interface IDistributorPatchRequest {
    version: number;
    disabled: boolean;
}

export const DistributorConfigService = new InjectionToken<IDistributorConfigService>("DistributorConfig");

@Injectable()
export class DistributorsService {
    public dateFormat: string;
    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(DistributorConfigService) private distributorConfig: IDistributorConfigService, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.dateFormat = this.distributorConfig.dateFormat;
        this.UTCDATEFORMAT = this.distributorConfig.utcDateFormat;
        this.datatableStateSaveMode = this.distributorConfig.datatableStateSaveMode;
    }

    public distributorOverview({
        params,
        isActive,
        disabled,
        status,
        fromPostCode,
        toPostCode,
        city,
        saleslineFilter,
        selectedLifecycleStatusFilter
    }: {
        params: IApiListQueryParameter;
        isActive?: boolean;
        disabled?: boolean;
        status?: DistributorStatus;
        fromPostCode?: string;
        toPostCode?: string;
        city?: string;
        saleslineFilter?: string;
        selectedLifecycleStatusFilter?: string;
    }): Observable<{ list: DistributorObj[]; count: number; total: number }> {
        let queryParams = params as any;

        if (isActive === true) {
            queryParams = {
                ...queryParams,
                isActive: true
            };
        }

        if (isActive === false) {
            queryParams = {
                ...queryParams,
                isActive: false
            };
        }

        if (disabled === true) {
            queryParams = {
                ...queryParams,
                disabled: true
            };
        }

        if (disabled === false) {
            queryParams = {
                ...queryParams,
                disabled: false
            };
        }

        if (status) {
            queryParams = {
                ...queryParams,
                status
            };
        }

        if (fromPostCode !== undefined) {
            queryParams = {
                ...queryParams,
                fromPostCode: fromPostCode
            };
        }

        if (toPostCode !== undefined) {
            queryParams = {
                ...queryParams,
                toPostCode: toPostCode
            };
        }

        if (city !== undefined) {
            queryParams = {
                ...queryParams,
                city: city
            };
        }

        if (saleslineFilter !== undefined) {
            queryParams = {
                ...queryParams,
                salesline: saleslineFilter
            };
        }

        if (selectedLifecycleStatusFilter !== undefined) {
            queryParams = {
                ...queryParams,
                lifecycleStatus: selectedLifecycleStatusFilter
            };
        }

        return this.http
            .get<any>(this.getDistributorsServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: queryParams,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    let count = result.body.length;
                    let total = result.body.length;
                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count"), 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count"), 10);
                    } catch (e) {}

                    return {
                        list: plainToClass(DistributorObj, result.body as any[]),
                        count: count,
                        total: total
                    };
                })
            );
    }

    public getLifecycleStatusItems(): Observable<string[]> {
        return this.http.get<string[]>(this.getDistributorsServiceUrl("lifecyclestatus"), {
            headers: this.oauthStorageAdapter.getAuthHeadersJSON()
        });
    }

    public getDistributorImportOverview(): Observable<DistributorImportOverview> {
        return this.http
            .get<Object>(this.getDistributorsServiceUrl("import"), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(DistributorImportOverview, result)));
    }

    public uploadDistributorList(formData: FormData): Observable<DistributorImportOverview> {
        return this.http
            .post<void>(this.getDistributorsServiceUrl("import"), formData, {
                headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType()
            })
            .pipe(map((result) => plainToClass(DistributorImportOverview, result)));
    }

    private getDistributorsServiceUrl(postfix: string): string {
        return this.distributorConfig.distributorsAPI + postfix;
    }

    public changeDistributorState(distributor: DistributorObj): Observable<DistributorObj> {
        const request: IDistributorPatchRequest = {
            version: distributor.version,
            disabled: !distributor.disabled
        };

        return this.http
            .patch<Object>(this.getDistributorsServiceUrl(distributor._id), request, {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(DistributorObj, result)));
    }
}
