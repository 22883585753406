<div class="modal-header">
    <h4 class="modal-title">{{ !isNoValidTour ? ("tours.dialog.title" | translate) : ("tours.dialog.altTitle" | translate) }}</h4>
</div>
<div class="modal-body">
    <form class="tour-form" id="tour-form" #f="ngForm" novalidate>
        <!--Selection of an existing tour-->
        <div *ngIf="!isCreationFormActive" class="selection-form">
            <div class="row">
                <div class="col-md-12">
                    <button type="button" (click)="showCreateTourForm()" class="btn btn-success btn--icon-text float-right ml-2 mb-2">
                        <i _ngcontent-air-c157="" class="zmdi zmdi-collection-plus"></i>{{ "tours.dialog.buttons.create" | translate }}
                    </button>
                </div>
            </div>
            <label>{{ "tours.dialog.labels.selector" | translate }}</label>
            <div class="row">
                <div class="col-12" style="padding-bottom: 15px">
                    <select [(ngModel)]="selected" class="form-control ng-pristine ng-valid ng-touched" name="tour-select" id="tour-select">
                        <option *ngFor="let selectorOption of selectorOptions" [value]="selectorOption._id">
                            {{ "tours.dialog.selectorOptions" | translate : { name: selectorOption.name, fromDate: selectorOption.fromDate, toDate: selectorOption.toDate } }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <!--Creation of a new tour-->
        <div *ngIf="isCreationFormActive">
            <!--name input-->
            <div class="col-md-12">
                <div class="form-group">
                    <label>{{ "tours.dialog.labels.name" | translate }}</label>
                    <span class="row">
                        <div class="col-auto">
                            <input class="form-control" name="nameInput" type="text" [(ngModel)]="tourNameValue" #name="ngModel" [ngClass]="{ 'is-invalid': name.invalid && f.submitted }" required />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip">
                                {{ "tours.dialog.validation.name" | translate }}
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <!--fromDate input-->
            <div class="col-md-12">
                <div class="form-group">
                    <label>{{ "tours.dialog.labels.fromdate" | translate }}</label>
                    <div class="row">
                        <div class="col-auto">
                            <input
                                class="form-control"
                                name="fromDateInput"
                                type="text"
                                [(ngModel)]="fromDateValue"
                                bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showMeridian: false, containerClass: 'theme-blue' }"
                                #fromDate="ngModel"
                                [ngClass]="{ 'is-invalid': fromDate.invalid }"
                                required
                            />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip">
                                {{ "tours.dialog.validation.fromdate" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--toDate input-->
            <div class="col-md-12">
                <div class="form-group">
                    <label>{{ "tours.dialog.labels.todate" | translate }}</label>
                    <div class="row">
                        <div class="col-auto">
                            <input
                                class="form-control"
                                name="toDateInput"
                                type="text"
                                [(ngModel)]="toDateValue"
                                bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showMeridian: false, containerClass: 'theme-blue' }"
                                #toDate="ngModel"
                                [ngClass]="{ 'is-invalid': toDate.invalid }"
                                required
                                appDateValidLowerLimit="fromDateValue"
                            />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip">
                                {{ "tours.dialog.validation.todate" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="float-right btn btn-success btn--icon-text" form="tour-form" (click)="onSaveBtn(f.valid)" [disabled]="isProcessing">
                <i class="zmdi zmdi-check"></i>{{ "tours.dialog.buttons.save" | translate }}
            </button>
            <button type="button" class="btn btn-link" (click)="close()">{{ "tours.dialog.buttons.close" | translate }}</button>
        </div>
    </form>
</div>
