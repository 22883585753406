import { Exclude, Type } from "class-transformer";

export class DistributorImportOverview {
    @Exclude({ toPlainOnly: true })
    quantity: number;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    lastImportDate?: Date;
}
