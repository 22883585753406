import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";

import { InterestedPartiesComponent } from "./interestedparties.component";
import { InterestedPartiesService, InterestedPartiesConfigService, IInterestedPartiesConfigService } from "./interestedparties.service";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { InterestedPartiesMainComponent } from "./interestedparties.main.component";

@NgModule({
    declarations: [InterestedPartiesMainComponent, InterestedPartiesComponent],
    imports: [CommonModule, RouterModule, FormsModule, DataTablesModule, BsDropdownModule, ModalModule, NgSelectModule, TranslateModule, OAuthModule],
    exports: [],
    providers: []
})
export class InterestedPartiesModule {
    static forRoot(interestedPartyConfig: IInterestedPartiesConfigService): ModuleWithProviders<InterestedPartiesModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: InterestedPartiesModule,
            providers: [
                InterestedPartiesService,
                {
                    provide: InterestedPartiesConfigService,
                    useValue: interestedPartyConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: InterestedPartiesMainComponent,
                children: [
                    {
                        path: "",
                        component: InterestedPartiesComponent
                    }
                ]
            }
        ];
    }
}
