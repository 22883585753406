<div class="content__inner">
    <header class="content__title">
        <h1>{{ "orderitems.list.title" | translate }}</h1>
        <small>{{ "orderitems.list.subtitle" | translate }}</small>
    </header>

    <div class="card" *appHasPermission="'ORDERITEMS.LIST'">
        <div class="card-body">
            <h4 class="card-title">{{ "orderitems.list.card.title" | translate }}</h4>
            <div class="actions">
                <button class="float-right btn btn-outline-secondary btn--icon-text" *appHasPermission="'ORDERITEMS.EXPORT'" (click)="downloadOrderItemsCSV()">
                    <i class="zmdi zmdi-download"></i> {{ "orderitems.list.card.export" | translate }}
                </button>
            </div>

            <div class="row datatable-filters">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "orderitems.filter.startdate.title" | translate }}</label>
                        <div class="row">
                            <div class="col-auto">
                                <input
                                    class="form-control"
                                    type="text"
                                    placeholder="{{ 'orderitems.filter.startdate.placeholder' | translate }}"
                                    bsDatepicker
                                    (bsValueChange)="filterChanged()"
                                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showMeridian: false, containerClass: 'theme-blue' }"
                                    [(ngModel)]="startDate"
                                />
                            </div>
                            <div class="col-auto time-picker-container right-of-datepicker">
                                <timepicker class="time-picker" [showMeridian]="false" [(ngModel)]="startDate"></timepicker>
                            </div>
                            <div *ngIf="startDate" (click)="resetStartDate()" class="ng-clear-wrapper" title="Clear all"><span aria-hidden="true" class="ng-clear">×</span></div>
                        </div>
                        <i class="form-group__bar"></i>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "orderitems.filter.enddate.title" | translate }}</label>
                        <div class="row">
                            <div class="col-auto">
                                <input
                                    class="form-control"
                                    type="text"
                                    placeholder="{{ 'orderitems.filter.enddate.placeholder' | translate }}"
                                    bsDatepicker
                                    (bsValueChange)="filterChanged()"
                                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showMeridian: false, containerClass: 'theme-blue' }"
                                    [(ngModel)]="endDate"
                                />
                            </div>
                            <div class="col-auto time-picker-container right-of-datepicker">
                                <timepicker class="time-picker" [showMeridian]="false" [(ngModel)]="endDate"></timepicker>
                            </div>
                            <div *ngIf="endDate" (click)="resetEndDate()" class="ng-clear-wrapper" title="Clear all"><span aria-hidden="true" class="ng-clear">×</span></div>
                        </div>
                        <i class="form-group__bar"></i>
                    </div>
                </div>
            </div>

            <table datatable class="table table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th id="order">{{ "orderitems.props.user" | translate }}</th>
                        <th id="quantity">{{ "orderitems.props.quantity" | translate }}</th>
                        <th id="name">{{ "orderitems.props.name" | translate }}</th>
                        <th id="materialNumber">{{ "orderitems.props.materialnumber" | translate }}</th>
                        <th id="category">{{ "orderitems.props.category" | translate }}</th>
                        <th id="created">{{ "orderitems.props.created" | translate }}</th>
                        <th id="updated">{{ "orderitems.props.updated" | translate }}</th>
                        <th id="actions"></th>
                    </tr>
                </thead>
                <tbody *ngIf="rows?.length != 0">
                    <tr *ngFor="let row of rows">
                        <td [innerHtml]="row.order?.htmlFormatUser()"></td>
                        <td>{{ row.quantity }}</td>
                        <td>{{ row.name }}</td>
                        <td>{{ row.materialNumber }}</td>
                        <td>{{ row.category }}</td>
                        <td>{{ row.created | date: UTCDATEFORMAT:"+0" }}</td>
                        <td>{{ row.updated | date: UTCDATEFORMAT:"+0" }}</td>
                        <td class="text-right nowrap">
                            <i (click)="editOrderItem(row._id)" class="zmdi zmdi-edit zmdi-hc-fw tableIcon" *appHasPermission="'ORDERITEMS.EDIT'"></i>
                            <i (click)="deleteOrderItem(row._id)" class="zmdi zmdi-delete zmdi-hc-fw tableIcon" *appHasPermission="'ORDERITEMS.DELETE'"></i>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="rows?.length == 0">
                    <tr>
                        <td colspan="6" class="no-data-available">{{ "global.datatable.empty" | translate }}</td>
                    </tr>
                </tbody>

                <tbody></tbody>
            </table>
        </div>
    </div>
</div>
