import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ChecklistTemplateService } from "./checklisttemplate.service";
import { AlertService, BaseComponent } from "@impacgroup/angular-baselib";
import { ChecklistTemplate, ChecklistTemplateTemporary } from "../../api-models/ChecklistTemplate";

import "brace";
import "brace/mode/html";
import "brace/mode/json";
import "brace/theme/eclipse";

@Component({
    selector: "app-checklisttemplate",
    templateUrl: "./checklisttemplate.component.html",
    styleUrls: ["checklisttemplate.component.scss"]
})
export class ChecklistTemplateComponent extends BaseComponent implements OnInit {
    public UTCDATEFORMAT: string = "";

    public checklistTemplate: ChecklistTemplate = new ChecklistTemplate();
    public checklistTemplateTemporary: ChecklistTemplateTemporary = new ChecklistTemplateTemporary();

    public agreementWYSIWYGMode = false;

    public settingsHTML = { theme: "eclipse", mode: "html", options: { tabSize: 2 } };
    public settingsJSON = { theme: "eclipse", mode: "json", options: { tabSize: 2 } };

    constructor(private checklistTemplateService: ChecklistTemplateService, private translateService: TranslateService, private alertService: AlertService) {
        super();
        this.UTCDATEFORMAT = this.checklistTemplateService.UTCDATEFORMAT;
    }

    ngOnInit() {
        this.subscriptions.push(
            this.checklistTemplateService.getChecklistTemplate().subscribe((result) => {
                this.processChecklist(result);
            })
        );
    }

    public toggleAgreementEditorMode() {
        this.agreementWYSIWYGMode = !this.agreementWYSIWYGMode;
    }

    public beautify(name: string) {
        try {
            this.checklistTemplateTemporary[name] = this.beautifyJSON(JSON.parse(this.checklistTemplateTemporary[name]));
        } catch (e) {
            const rawErrorMessage = e.toString();
            const elementName = this.translateService.instant(`checklisttemplate.${name}.title`);
            const readableErrorMessage = this.translateService.instant("checklisttemplate.editor.cantbeautify", { name: elementName, error: rawErrorMessage });
            this.alertService.addCustomError(readableErrorMessage);
        }
    }

    public save() {
        this.checklistTemplate.content = JSON.parse(this.checklistTemplateTemporary.content);
        this.checklistTemplate.photosNormal = JSON.parse(this.checklistTemplateTemporary.photosNormal);
        this.checklistTemplate.photosSilber = JSON.parse(this.checklistTemplateTemporary.photosSilber);
        this.checklistTemplate.photosGold = JSON.parse(this.checklistTemplateTemporary.photosGold);

        this.subscriptions.push(
            this.checklistTemplateService.updateChecklistTemplate(this.checklistTemplate).subscribe((result) => {
                this.processChecklist(result);
                this.alertService.addSuccess(this.translateService.instant("checklisttemplate.update.success"));
            })
        );
    }

    private processChecklist(result) {
        this.checklistTemplate = result;
        this.checklistTemplateTemporary.content = this.beautifyJSON(result.content);
        this.checklistTemplateTemporary.photosNormal = this.beautifyJSON(result.photosNormal);
        this.checklistTemplateTemporary.photosSilber = this.beautifyJSON(result.photosSilber);
        this.checklistTemplateTemporary.photosGold = this.beautifyJSON(result.photosGold);
    }

    private beautifyJSON(content: string | Object) {
        return JSON.stringify(content, null, "\t");
    }
}
