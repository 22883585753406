import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";

import { ShopItemsComponent } from "./shopitems.component";
import { ShopItemsService, ShopItemConfigService, IShopItemConfigService } from "./shopitems.service";
import { ShopItemDetailComponent } from "./shopitemdetail.component";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { ShopItemsMainComponent } from "./shopitems.main.component";

@NgModule({
    declarations: [ShopItemsMainComponent, ShopItemsComponent, ShopItemDetailComponent],
    imports: [CommonModule, RouterModule, FormsModule, DataTablesModule, BsDropdownModule, ModalModule, NgSelectModule, TranslateModule, OAuthModule],
    exports: [],
    providers: []
})
export class ShopItemsModule {
    static forRoot(shopItemConfig: IShopItemConfigService): ModuleWithProviders<ShopItemsModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: ShopItemsModule,
            providers: [
                ShopItemsService,
                {
                    provide: ShopItemConfigService,
                    useValue: shopItemConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: ShopItemsMainComponent,
                children: [
                    {
                        path: "",
                        component: ShopItemsComponent
                    },
                    {
                        path: "add",
                        component: ShopItemDetailComponent
                    },
                    {
                        path: ":id",
                        component: ShopItemDetailComponent
                    }
                ]
            }
        ];
    }
}
