<div class="content__inner">
    <header class="content__title">
        <h1>{{ "tours.list.title" | translate }}</h1>
        <small>{{ "tours.list.subtitle" | translate }}</small>

        <div class="actions">
            <button *appHasPermission="'TOURS.ADD'" (click)="addTour()" class="btn btn-success btn--icon-text" style="margin-left: 25px"><i class="zmdi zmdi-collection-plus"></i> {{ "tours.list.add" | translate }}</button>
        </div>
    </header>

    <div class="card" *appHasPermission="'TOURS.LIST'">
        <div class="card-body">
            <h4 class="card-title">{{ "tours.list.card.title" | translate }}</h4>

            <table datatable class="table table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th id="name">{{ "tours.props.name" | translate }}</th>
                        <th id="fromDate">{{ "tours.props.fromdate" | translate }}</th>
                        <th id="toDate">{{ "tours.props.todate" | translate }}</th>
                        <th id="visited">{{ "tours.props.visited" | translate }}</th>
                        <th id="createdByMail">{{ "tours.props.createdByMail" | translate }}</th>
                        <th id="actions"></th>
                    </tr>
                </thead>
                <tbody *ngIf="rows?.length != 0">
                    <tr *ngFor="let row of rows">
                        <td [attr.role]="hasToursEditPermission ? 'button' : undefined" (click)="hasToursEditPermission && editTour(row._id)">{{ row.name }}</td>
                        <td [attr.role]="hasToursEditPermission ? 'button' : undefined" (click)="hasToursEditPermission && editTour(row._id)">{{ row.fromDate | date : "yyyy-MM-dd" }}</td>
                        <td [attr.role]="hasToursEditPermission ? 'button' : undefined" (click)="hasToursEditPermission && editTour(row._id)">{{ row.toDate | date : "yyyy-MM-dd" }}</td>
                        <td [attr.role]="hasToursEditPermission ? 'button' : undefined" (click)="hasToursEditPermission && editTour(row._id)">
                            {{ row.visitedDistributorCount }} / {{ row.totalDistributorCount }} ( {{ row.totalDistributorCount !== 0 ? (row.visitedDistributorCount / row.totalDistributorCount | percent : "1.0") : "0%" }} )
                        </td>
                        <td [attr.role]="hasToursEditPermission ? 'button' : undefined" (click)="hasToursEditPermission && editTour(row._id)">
                            {{ row.createdByMail }}
                        </td>
                        <td class="nowrap">
                            <div class="d-flex justify-content-end">
                                <span class="flex-grow-1 position-relative" *appHasPermission="'TOURS.EDIT'">
                                    <span role="button" (click)="editTour(row._id)" class="position-absolute tour-action-padding"> </span>
                                </span>
                                <i (click)="editTour(row._id)" class="zmdi zmdi-edit zmdi-hc-fw tableIcon" *appHasPermission="'TOURS.EDIT'"></i>
                                <i (click)="duplicateTour(row._id, row.name)" class="zmdi zmdi-copy zmdi-hc-fw tableIcon" *appHasPermission="'TOURS.EDIT'"></i>
                                <i (click)="deleteTour(row._id)" class="zmdi zmdi-delete zmdi-hc-fw tableIcon" *appHasPermission="'TOURS.DELETE'"></i>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="rows?.length == 0">
                    <tr>
                        <td colspan="6" class="no-data-available">{{ "global.datatable.empty" | translate }}</td>
                    </tr>
                </tbody>

                <tbody></tbody>
            </table>
        </div>
    </div>
</div>
