import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { IApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { classToPlain, plainToClass } from "class-transformer";
import { OrderItem } from "../../api-models/OrderItem";

export interface IOrderItemConfigService {
    orderItemsAPI: string;
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const OrderItemConfigService = new InjectionToken<IOrderItemConfigService>("OrderItemConfig");

@Injectable()
export class OrderItemsService {
    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(OrderItemConfigService) private orderItemConfig: IOrderItemConfigService, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.UTCDATEFORMAT = this.orderItemConfig.utcDateFormat;
        this.datatableStateSaveMode = this.orderItemConfig.datatableStateSaveMode;
    }

    public orderItems(params: IApiListQueryParameter): Observable<{ list: OrderItem[]; count: number; total: number }> {
        return this.http
            .get<any>(this.getOrderItemsServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: params as any,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    let count = result.body.length;
                    let total = result.body.length;
                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count"), 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count"), 10);
                    } catch (e) {}

                    return {
                        list: plainToClass(OrderItem, result.body as any[]),
                        count: count,
                        total: total
                    };
                })
            );
    }

    public downloadOrderItemsCSV(params: IApiListQueryParameter): Observable<Blob> {
        return this.http.get(this.getOrderItemsServiceUrl("csv"), {
            headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType(),
            params: params as any,
            responseType: "blob"
        });
    }

    private getOrderItemsServiceUrl(postfix: string): string {
        return this.orderItemConfig.orderItemsAPI + postfix;
    }
}
