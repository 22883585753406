<div class="content__inner">
    <header class="content__title">
        <h1 *ngIf="MODE === 'ADD'">{{ "shopitems.detail.add.title" | translate }}</h1>
        <h1 *ngIf="MODE === 'EDIT'">{{ "shopitems.detail.edit.title" | translate }}</h1>

        <div class="actions">
            <button (click)="back()" class="btn btn-secondary btn--icon-text"><i class="zmdi zmdi-arrow-back"></i> {{ "global.buttons.back" | translate }}</button>
        </div>
    </header>

    <div class="card">
        <div class="card-body">
            <form name="form" class="row" *ngIf="shopItem" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{ "shopitems.props.name" | translate }}</label>
                        <input name="name" type="text" class="form-control" [(ngModel)]="shopItem.name" #name="ngModel" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "shopitems.detail.validation.name" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{ "shopitems.props.materialnumber" | translate }}</label>
                        <input name="materialNumber" type="text" class="form-control" [(ngModel)]="shopItem.materialNumber" #materialNumber="ngModel" [ngClass]="{ 'is-invalid': f.submitted && materialNumber.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "shopitems.detail.validation.materialnumber" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{ "shopitems.props.category" | translate }}</label>
                        <ng-select
                            name="category"
                            [(ngModel)]="shopItem.category"
                            #category="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && !shopItem.category }"
                            [items]="categories"
                            bindLabel="label"
                            bindValue="value"
                            [placeholder]="'shopitems.detail.edit.category.placeholder' | translate"
                        >
                        </ng-select>
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "shopitems.detail.validation.category" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <button class="float-right btn btn-success btn--icon-text" [disabled]="f.submitted && f.invalid"><i class="zmdi zmdi-check"></i> {{ "global.buttons.save" | translate }}</button>
                </div>
            </form>
        </div>
    </div>
</div>
