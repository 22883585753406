import { AdminTourCreateResponseDTO } from "@impacgroup/ayyildiz-checklist-platform-api-dtos";
import { Expose } from "class-transformer";
import { IsNotEmpty, IsString } from "class-validator";

export class AdminTourDuplicateRequestDTO {
    @Expose()
    @IsNotEmpty()
    @IsString()
    targetTourName?: string;
}

export class AdminTourDuplicateResponseDTO extends AdminTourCreateResponseDTO {
    @Expose()
    failcounter?: number;
}
