import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { IApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { plainToClass } from "class-transformer";
import { InterestedParty } from "../../api-models/InterestedParty";

export interface IInterestedPartiesConfigService {
    interestedPartiesAPI: string;
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const InterestedPartiesConfigService = new InjectionToken<IInterestedPartiesConfigService>("InterestedPartiesConfig");

@Injectable()
export class InterestedPartiesService {
    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(InterestedPartiesConfigService) private interestedPartiesConfig: IInterestedPartiesConfigService, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.UTCDATEFORMAT = this.interestedPartiesConfig.utcDateFormat;
        this.datatableStateSaveMode = this.interestedPartiesConfig.datatableStateSaveMode;
    }

    public interestedParties(params: IApiListQueryParameter): Observable<{ list: InterestedParty[]; count: number; total: number }> {
        return this.http
            .get<any>(this.getInterestedPartiesServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: params as any,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    let count = result.body.length;
                    let total = result.body.length;
                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count"), 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count"), 10);
                    } catch (e) {}

                    return {
                        list: plainToClass(InterestedParty, result.body as any[]),
                        count: count,
                        total: total
                    };
                })
            );
    }

    public deleteInterestedParties(id: string): Observable<InterestedParty> {
        return this.http
            .delete<Object>(this.getInterestedPartiesServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(InterestedParty, result)));
    }

    private getInterestedPartiesServiceUrl(postfix: string): string {
        return this.interestedPartiesConfig.interestedPartiesAPI + postfix;
    }
}
