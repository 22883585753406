import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";
import * as moment from "moment";

export function validDateLowerLimitValidator(fromDate: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!moment(control.value).isValid() || !moment(fromDate).isValid()) {
            return { invalidDate: true };
        }
        const toMoment = moment(control.value).endOf("day");
        const fromMoment = moment(fromDate).startOf("day");

        if (toMoment.isSameOrBefore(fromMoment)) {
            return { invalidRange: true };
        }
        return null;
    };
}

@Directive({
    selector: "[appDateValidLowerLimit]",
    providers: [{ provide: NG_VALIDATORS, useExisting: DateLowerLimitDirective, multi: true }]
})
export class DateLowerLimitDirective implements Validator {
    @Input("fromDateValidation") fromDateValidation: string;

    validate(control: AbstractControl): ValidationErrors | null {
        return validDateLowerLimitValidator(this.fromDateValidation)(control);
    }
}
