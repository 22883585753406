import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { DistributorsService } from "./distributors.service";
import { AlertService, BaseComponent, ConfirmDialogComponent } from "@impacgroup/angular-baselib";
import { DistributorImportOverview } from "../../api-models/DistributorImportOverview";
import { DistributorsMainComponent } from "./distributors.main.component";

@Component({
    selector: "app-distributorexport",
    templateUrl: "./distributorimport.component.html",
    styleUrls: ["distributorimport.component.scss"]
})
export class DistributorImportComponent extends BaseComponent implements OnInit {
    public distributorImportOverview: DistributorImportOverview;

    constructor(
        private distributorsService: DistributorsService,
        private translateService: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private alertService: AlertService,
        parent: DistributorsMainComponent
    ) {
        super();
    }

    ngOnInit() {
        this.subscriptions.push(
            this.distributorsService.getDistributorImportOverview().subscribe((result) => {
                this.distributorImportOverview = result;
            })
        );
    }

    uploadDistributorList(files: FileList) {
        if (files.length === 0) {
            return;
        }

        const mimeType = files[0].type;
        if (mimeType.match(/csv\/*/) == null) {
            this.alertService.addCustomErrorByI18nId("distributors.list.validation.upload_csv");
            return;
        }

        const file: File = files[0];

        const formData: FormData = new FormData();
        formData.append("file", file, file.name);

        this.subscriptions.push(
            this.distributorsService.uploadDistributorList(formData).subscribe((result) => {
                this.distributorImportOverview = result;
                this.alertService.addSuccess(this.translateService.instant("distributors.list.upload.success"));
            })
        );
    }
}
