import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

import { DataTableSelectedCountComponent, DistributorOverviewComponent } from "./distributoroverview.component";
import { DistributorImportComponent } from "./distributorimport.component";
import { DistributorsService, DistributorConfigService, IDistributorConfigService } from "./distributors.service";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { DistributorsMainComponent } from "./distributors.main.component";
import { TourDistributionDialogComponent } from "src/app/modal/tourdistribution-dialog.component";
import { ValidDateLowerLimitModule } from "src/app/directive/valid-date-lower-limit/valid-date-lower-limit.module";

@NgModule({
    declarations: [DistributorsMainComponent, DistributorOverviewComponent, DistributorImportComponent, DataTableSelectedCountComponent, TourDistributionDialogComponent],
    imports: [CommonModule, RouterModule, FormsModule, DataTablesModule, BsDropdownModule, ModalModule, NgSelectModule, TranslateModule, OAuthModule, ValidDateLowerLimitModule, BsDatepickerModule.forRoot()],
    exports: [],
    providers: []
})
export class DistributorsModule {
    static forRoot(distributorConfig: IDistributorConfigService): ModuleWithProviders<DistributorsModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: DistributorsModule,
            providers: [
                DistributorsService,
                {
                    provide: DistributorConfigService,
                    useValue: distributorConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                redirectTo: "overview",
                pathMatch: "full"
            },
            {
                path: "",
                component: DistributorsMainComponent,
                children: [
                    {
                        path: "overview",
                        component: DistributorOverviewComponent
                    },
                    {
                        path: "import",
                        component: DistributorImportComponent
                    }
                ]
            }
        ];
    }
}
