import { Injectable } from "@angular/core";
import { DateParseMode, VisitReportMadListResponseDTO } from "src/app/api-models/VisitReport";

@Injectable()
export class VisitReportsListStateService {
    mads?: VisitReportMadListResponseDTO[];
    datemode: DateParseMode = "day";

    // Date filter ( datemode === "day" )
    filterStartDate?: Date;
    filterEndDate?: Date;

    // Date filter ( datemode === "week" )
    weekFilterOptions: { key: string; value: string }[] = [];
    filterStartWeek?: string;
    filterEndWeek?: string;

    // Date filter ( datemode === "month" )
    monthFilterOptions: { key: string; value: string }[] = [];
    filterStartMonth?: string;
    filterEndMonth?: string;
}
