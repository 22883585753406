<div class="content__inner">
    <header class="content__title">
        <h1 *ngIf="MODE === 'ADD'">{{ "flyerimages.detail.add.title" | translate }}</h1>
        <h1 *ngIf="MODE === 'EDIT'">{{ "flyerimages.detail.edit.title" | translate }}</h1>

        <div class="actions">
            <button (click)="back()" class="btn btn-secondary btn--icon-text"><i class="zmdi zmdi-arrow-back"></i> {{ "global.buttons.back" | translate }}</button>
        </div>
    </header>

    <div class="card">
        <div class="card-body" (drag)="allowDrop($event)" (dragover)="onFormDragEnter($event)" (dragleave)="onFormDragLeave($event)">
            <form name="form" class="row" [ngClass]="{ dragover: showDropPlaces }" *ngIf="flyerImage" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
                <div class="col-md-12" *ngIf="MODE === 'EDIT'">
                    <div class="form-group">
                        <label>{{ "flyerimages.props._id" | translate }}</label>
                        <input name="_id" type="text" class="form-control" [ngModel]="flyerImage._id" readonly />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{ "flyerimages.props.description" | translate }}</label>
                        <input name="description" type="text" class="form-control" [(ngModel)]="flyerImage.description" #description="ngModel" [ngClass]="{ 'is-invalid': f.submitted && description.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "flyerimages.detail.validation.description" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div *ngIf="MODE === 'ADD'">
                        <div class="form-group">
                            <label>{{ "flyerimages.props.filename" | translate }}</label>
                            <input
                                name="filename"
                                type="text"
                                class="form-control"
                                [(ngModel)]="flyerImage.filename"
                                #filename="ngModel"
                                [ngClass]="{ 'is-invalid': f.submitted && filename.invalid && flyerImage.filename != '' }"
                                readonly
                                required
                            />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip">
                                {{ "flyerimages.detail.validation.filename" | translate }}
                            </div>
                        </div>
                    </div>

                    <div *ngIf="MODE === 'EDIT'">
                        <div class="form-group">
                            <label>{{ "flyerimages.props.filename" | translate }}</label>
                            <input name="filename" type="text" class="form-control" [(ngModel)]="flyerImage.filename" #filename="ngModel" [ngClass]="{ 'is-invalid': f.submitted && filename.invalid }" readonly />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip">
                                {{ "flyerimages.detail.validation.filename" | translate }}
                            </div>
                        </div>
                    </div>

                    <div *ngIf="returnFlyerImagePreviewImage">
                        <div class="preview-image-container">
                            <div class="preview-image" (drop)="onDrop($event)">
                                <img [src]="returnFlyerImagePreviewImage" />
                            </div>
                        </div>
                        <br />
                        <br />
                    </div>

                    <div *ngIf="MODE === 'ADD'" class="upload-flyer-image-container" (drop)="onDrop($event)">
                        <button (click)="fileInput.click()" class="btn btn-success btn--icon-text" type="button"><i class="zmdi zmdi-collection-plus"></i> {{ "flyerimages.detail.add.upload.button" | translate }}</button>
                        <input #fileInput type="file" (change)="chooseFile($event.target.files)" />
                    </div>

                    <div *ngIf="MODE === 'EDIT'" class="upload-flyer-image-container" (drop)="onDrop($event)">
                        <button (click)="fileInput.click()" class="btn btn-success btn--icon-text" type="button"><i class="zmdi zmdi-collection-plus"></i> {{ "flyerimages.detail.edit.upload.button" | translate }}</button>
                        <input #fileInput type="file" (change)="chooseFile($event.target.files)" />
                    </div>
                </div>

                <div class="col-md-12">
                    <button class="float-right btn btn-success btn--icon-text" [disabled]="f.submitted && f.invalid"><i class="zmdi zmdi-check"></i> {{ "global.buttons.save" | translate }}</button>
                </div>
            </form>
        </div>
    </div>
</div>
