import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";

import { FlyerImagesComponent } from "./flyerimages.component";
import { FlyerImagesService, FlyerImageConfigService, IFlyerImageConfigService } from "./flyerimages.service";
import { FlyerImageDetailComponent } from "./flyerimagedetail.component";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { FlyerImagesMainComponent } from "./flyerimages.main.component";

@NgModule({
    declarations: [FlyerImagesMainComponent, FlyerImagesComponent, FlyerImageDetailComponent],
    imports: [CommonModule, RouterModule, FormsModule, DataTablesModule, BsDropdownModule, ModalModule, NgSelectModule, TranslateModule, OAuthModule],
    exports: [],
    providers: []
})
export class FlyerImagesModule {
    static forRoot(flyerImageConfig: IFlyerImageConfigService): ModuleWithProviders<FlyerImagesModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: FlyerImagesModule,
            providers: [
                FlyerImagesService,
                {
                    provide: FlyerImageConfigService,
                    useValue: flyerImageConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: FlyerImagesMainComponent,
                children: [
                    {
                        path: "",
                        component: FlyerImagesComponent
                    },
                    {
                        path: "add",
                        component: FlyerImageDetailComponent
                    },
                    {
                        path: ":id",
                        component: FlyerImageDetailComponent
                    }
                ]
            }
        ];
    }
}
