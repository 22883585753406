import { Component } from "@angular/core";
import { BaseComponent, IDatatableStateSaveableComponent } from "@impacgroup/angular-baselib";

@Component({
    selector: "app-checklists-main",
    templateUrl: "./checklists.main.component.html"
})
export class ChecklistsMainComponent extends BaseComponent implements IDatatableStateSaveableComponent {
    public listStateData: any;
    public showDifferenceDetected: boolean = true;
    public showNoDifferenceDetected: boolean = true;
    public showNeutral: boolean = true;
    public showMarked: boolean = true;
    public showApproved: boolean = true;
    public showApprovalConfirmed: boolean = false;

    // This parameter will be an optional handover from "visitreports" module section
    // Therefore be adviced => valid values may be those:
    // mode = DateParseMode
    // date = String formatted date, dependent from given mode (see VisitReportListResponseDTO.getFormattedDate() methode)
    // userFilter = valid user id
    public visitReportParams?: { mode?: string; date?: string; userFilter?: string };
}
