import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";

import { ToursComponent } from "./tours.component";
import { ToursService, TourServiceConfig, ITourConfigService } from "./tours.service";
import { TourDetailComponent } from "./tourdetail.component";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { ToursMainComponent } from "./tours.main.component";
import { ITourRepositoryConfig, TourRepositoryConfig, ToursRepository } from "./tours.repository";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { PopoverModule } from "ngx-bootstrap/popover";

@NgModule({
    declarations: [ToursMainComponent, ToursComponent, TourDetailComponent],
    imports: [CommonModule, RouterModule, FormsModule, PopoverModule.forRoot(), DataTablesModule, BsDropdownModule, ModalModule, NgSelectModule, TranslateModule, OAuthModule, BsDatepickerModule.forRoot()],
    exports: [],
    providers: []
})
export class ToursModule {
    static forRoot(tourConfig: ITourConfigService & ITourRepositoryConfig): ModuleWithProviders<ToursModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: ToursModule,
            providers: [
                ToursService,
                {
                    provide: TourServiceConfig,
                    useValue: tourConfig
                },
                ToursRepository,
                {
                    provide: TourRepositoryConfig,
                    useValue: tourConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: ToursMainComponent,
                children: [
                    {
                        path: "",
                        component: ToursComponent
                    },
                    {
                        path: "add",
                        component: TourDetailComponent
                    },
                    {
                        path: ":id",
                        component: TourDetailComponent
                    }
                ]
            }
        ];
    }
}
