import { Directive } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

export function validJsonValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        try {
            JSON.parse(control.value);
            return null;
        } catch (e) {
            return { validJson: true };
        }
    };
}

@Directive({
    selector: "[appValidJson]",
    providers: [{ provide: NG_VALIDATORS, useExisting: ValidJsonValidatorDirective, multi: true }]
})
export class ValidJsonValidatorDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors | null {
        return validJsonValidator()(control);
    }
}
