import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable, OperatorFunction, Subscription } from "rxjs";
import { IApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { TourDetailModel, TourDialogModel, TourDistributorsCreateModel, TourDistributorsTransferModel, TourDistributorsTransferResponseModel, TourListModel, TourDuplicateModel } from "../../api-models/Tour";
import { ToursRepository } from "./tours.repository";
import * as moment from "moment";
import { plainToClass } from "class-transformer";
import { DistributorStatus } from "src/app/api-models/Checklist";
import {
    AdminTourCreateRequestDTO,
    AdminTourCreateResponseDTO,
    AdminTourDetailResponseDTO,
    AdminTourDistributorCreateRequestDTO,
    AdminTourDistributorCreateResponseDTO,
    AdminTourDistributorTransferRequestDTO,
    AdminTourDistributorUpdateVisitedRequestDTO,
    AdminTourUpdateRequestDTO
} from "@impacgroup/ayyildiz-checklist-platform-api-dtos";
import { TourDistributorListModel } from "src/app/api-models/TourDistributor";
import { AdminTourDuplicateRequestDTO } from "./AdminTourApiService.dto";

export interface ITourConfigService {
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const TourServiceConfig = new InjectionToken<ITourConfigService>("TourConfig");

@Injectable()
export class ToursService {
    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(TourServiceConfig) private tourConfig: ITourConfigService, private toursRepository: ToursRepository) {
        this.UTCDATEFORMAT = this.tourConfig.utcDateFormat;
        this.datatableStateSaveMode = this.tourConfig.datatableStateSaveMode;
    }

    // --------------------- '/admin/tour' endpoint --------------------- //
    // currentAndFutureOnly - toDate >= now
    public list(params: IApiListQueryParameter, currentAndFutureOnly?: boolean): Observable<{ list: TourListModel[]; count: number; total: number }> {
        return this.toursRepository.list(params, currentAndFutureOnly).pipe(
            map((result) => {
                return {
                    list: result.list.map((dto) => {
                        return {
                            ...dto
                        };
                    }),
                    count: result.count,
                    total: result.total
                };
            })
        );
    }

    public create(obj: TourDialogModel): Observable<AdminTourCreateResponseDTO> {
        return this.toursRepository.create(plainToClass(AdminTourCreateRequestDTO, this.setFromDateTimeAndToDateTime(obj), { excludeExtraneousValues: true }));
    }

    public read(id: string): Observable<TourDetailModel> {
        return this.toursRepository.read(id).pipe(this.convertTourDetailDTOtoViewModel());
    }

    public update(obj: TourDetailModel): Observable<TourDetailModel> {
        if (!obj._id) {
            throw new Error("Cannot update object without _id");
        }

        return this.toursRepository.update(obj._id, plainToClass(AdminTourUpdateRequestDTO, this.setFromDateTimeAndToDateTime(obj), { excludeExtraneousValues: true })).pipe(this.convertTourDetailDTOtoViewModel());
    }

    public delete(id: string): Observable<TourDetailModel> {
        return this.toursRepository.delete(id).pipe(this.convertTourDetailDTOtoViewModel());
    }

    public duplicate(id: string, targetTourName: string): Observable<TourDuplicateModel> {
        return this.toursRepository.duplicate(id, plainToClass(AdminTourDuplicateRequestDTO, { targetTourName }, { excludeExtraneousValues: true })).pipe(
            map((result) => {
                return { ...result };
            })
        );
    }

    // --------------------- '/admin/tours/:tourId/distributors' endpoint --------------------- //
    public listTourDistributors({
        tourId,
        params,
        status,
        fromPostCode,
        toPostCode,
        city,
        saleslineFilter,
        selectedLifecycleStatusFilter
    }: {
        tourId: string;
        params: IApiListQueryParameter;
        status?: DistributorStatus;
        fromPostCode?: string;
        toPostCode?: string;
        city?: string;
        saleslineFilter?: string;
        selectedLifecycleStatusFilter?: string;
    }): Observable<{ list: TourDistributorListModel[]; count: number; total: number }> {
        return this.toursRepository.listTourDistributors({ tourId, params, status, fromPostCode, toPostCode, city, saleslineFilter, selectedLifecycleStatusFilter }).pipe(
            map((result) => {
                const list = result.list.map((item) => {
                    return plainToClass(TourDistributorListModel, item, { excludeExtraneousValues: true });
                });

                return { list, count: result.count, total: result.total };
            })
        );
    }

    public csvExportTourDistributors({
        tourId,
        params,
        status,
        fromPostCode,
        toPostCode,
        city,
        saleslineFilter,
        selectedLifecycleStatusFilter
    }: {
        tourId: string;
        params: IApiListQueryParameter;
        status?: DistributorStatus;
        fromPostCode?: string;
        toPostCode?: string;
        city?: string;
        saleslineFilter?: string;
        selectedLifecycleStatusFilter?: string;
    }) {
        return this.toursRepository.csvExportTourDistributors({ tourId, params, status, fromPostCode, toPostCode, city, saleslineFilter, selectedLifecycleStatusFilter });
    }

    public createTourDistributors(
        obj: TourDistributorsCreateModel,
        params: {
            searchTerm: string;
            isActiveOnly: boolean;
            isInactiveOnly: boolean;
            isDisabledOnly: boolean;
            isStatus: DistributorStatus | "undefined";
            fromPostCode: string;
            toPostCode: string;
            city: string;
        }
    ): Observable<AdminTourDistributorCreateResponseDTO> {
        let queryParams: any = { search: params.searchTerm };
        const isActive = params.isActiveOnly ? true : params.isInactiveOnly ? false : undefined;
        if (isActive !== undefined) {
            queryParams = {
                ...queryParams,
                isActive
            };
        }
        const disabled = params.isDisabledOnly ? true : undefined;
        if (disabled !== undefined) {
            queryParams = {
                ...queryParams,
                disabled
            };
        }
        const status = params.isStatus && params.isStatus !== "undefined" ? params.isStatus : undefined;
        if (status !== undefined) {
            queryParams = {
                ...queryParams,
                status
            };
        }

        const fromPostCode = params.fromPostCode !== undefined ? params.fromPostCode : undefined;
        if (params.fromPostCode !== undefined) {
            queryParams = {
                ...queryParams,
                fromPostCode
            };
        }

        const toPostCode = params.toPostCode !== undefined ? params.toPostCode : undefined;
        if (params.toPostCode !== undefined) {
            queryParams = {
                ...queryParams,
                toPostCode
            };
        }

        const city = params.city !== undefined ? params.city : undefined;
        if (params.city !== undefined) {
            queryParams = {
                ...queryParams,
                city
            };
        }

        const apiObj: AdminTourDistributorCreateRequestDTO = plainToClass(
            AdminTourDistributorCreateRequestDTO,
            {
                ...obj,
                tour: obj.tourId
            },
            { excludeExtraneousValues: true }
        );

        return this.toursRepository.createTourDistributors(apiObj, queryParams);
    }

    public deleteTourDistributor({ tourId, tourDistributorId }: { tourId: string; tourDistributorId: string }): Observable<Object> {
        return this.toursRepository.deleteTourDistributor({ tourId, tourDistributorId });
    }

    public setTourDistributorVisited({ tourId, tourDistributorId, tourDistributorVersion }: { tourId: string; tourDistributorId: string; tourDistributorVersion: number }): Observable<Object> {
        const obj = {
            visited: true,
            version: tourDistributorVersion
        };
        return this.toursRepository.setTourDistributorVisited({ tourId, tourDistributorId, dto: plainToClass(AdminTourDistributorUpdateVisitedRequestDTO, obj, { excludeExtraneousValues: true }) });
    }

    public unsetTourDistributorVisited({ tourId, tourDistributorId, tourDistributorVersion }: { tourId: string; tourDistributorId: string; tourDistributorVersion: number }): Observable<Object> {
        const obj = {
            visited: false,
            version: tourDistributorVersion
        };

        return this.toursRepository.setTourDistributorVisited({ tourId, tourDistributorId, dto: plainToClass(AdminTourDistributorUpdateVisitedRequestDTO, obj, { excludeExtraneousValues: true }) });
    }

    public transferTourDistributors({ tourId, obj }: { tourId: string; obj: TourDistributorsTransferModel }): Observable<TourDistributorsTransferResponseModel> {
        return this.toursRepository
            .transferTourDistributors({
                tourId,
                dto: plainToClass(
                    AdminTourDistributorTransferRequestDTO,
                    {
                        ...obj
                    },
                    { excludeExtraneousValues: true }
                )
            })
            .pipe(
                map((result) => {
                    return { ...result };
                })
            );
    }

    private setFromDateTimeAndToDateTime(obj: TourDetailModel) {
        return {
            ...obj,
            fromDate: moment(obj.fromDate).startOf("day").toDate(),
            toDate: moment(obj.toDate).endOf("day").toDate()
        };
    }

    private convertTourDetailDTOtoViewModel(): OperatorFunction<AdminTourDetailResponseDTO, TourDetailModel> {
        return map((result) => {
            return {
                ...result,
                fromDate: moment(result.fromDate).toDate(),
                toDate: moment(result.toDate).toDate()
            };
        });
    }
}
