import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TimepickerModule } from "ngx-bootstrap/timepicker";

import { OrderItemsComponent } from "./orderitems.component";
import { OrderItemsService, OrderItemConfigService, IOrderItemConfigService } from "./orderitems.service";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { OrderItemsMainComponent } from "./orderitems.main.component";

@NgModule({
    declarations: [OrderItemsMainComponent, OrderItemsComponent],
    imports: [CommonModule, RouterModule, FormsModule, DataTablesModule, BsDropdownModule, ModalModule, NgSelectModule, TranslateModule, OAuthModule, BsDatepickerModule.forRoot(), TimepickerModule.forRoot()],
    exports: [],
    providers: []
})
export class OrderItemsModule {
    static forRoot(orderItemConfig: IOrderItemConfigService): ModuleWithProviders<OrderItemsModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: OrderItemsModule,
            providers: [
                OrderItemsService,
                {
                    provide: OrderItemConfigService,
                    useValue: orderItemConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: OrderItemsMainComponent,
                children: [
                    {
                        path: "",
                        component: OrderItemsComponent
                    }
                ]
            }
        ];
    }
}
