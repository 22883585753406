import { Component } from "@angular/core";
import { BaseComponent, IDatatableStateSaveableComponent } from "@impacgroup/angular-baselib";

@Component({
    selector: "app-shopitems-main",
    templateUrl: "./shopitems.main.component.html"
})
export class ShopItemsMainComponent extends BaseComponent implements IDatatableStateSaveableComponent {
    public listStateData: any;
}
