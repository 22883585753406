import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER, Injector } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule, TranslateLoader, TranslateService } from "@ngx-translate/core";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AppService } from "./app.service";
import { AppComponent } from "./app.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { HeaderComponent } from "./layout/header/header.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { LogoComponent } from "./layout/header/logo/logo.component";
import { NavigationTriggerComponent } from "./layout/header/navigation-trigger/navigation-trigger.component";
import { UserComponent } from "./layout/sidebar/user/user.component";
import { PageLoaderComponent } from "./layout/page-loader/page-loader.component";

import { FullLayoutComponent } from "./layout/layouts/layout-full/layout.component";
import { SingleLayoutComponent } from "./layout/layouts/layout-single/layout.component";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { NgScrollbarModule } from "ngx-scrollbar";
import { AppRoutingModule } from "./app-routing.module";
import { translateLoaderService } from "./global/translate/translateloader.service";
import { NgSelectModule } from "@ng-select/ng-select";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { PermissionsConfig } from "./config/PermissionsConfig";
import { environment } from "../environments/environment";
import { AngularBaseModule, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { HttpErrorInterceptor } from "./global/api-handling/HttpErrorInterceptor";
import { FormsModule } from "@angular/forms";
import { AlertModule } from "ngx-bootstrap/alert";
import { OrganizationsModule, RolesModule, UsersModule } from "@impacgroup/angular-access-management";
import { ProfileModule, ResetPasswordModule } from "@impacgroup/angular-selfservice";
import { LOCATION_INITIALIZED } from "@angular/common";
import { HttpLoadingService } from "./global/api-handling/HttpLoadingService";
import { LanguagesModule, LanguagesService } from "@impacgroup/angular-languages";
import { MailTemplatesModule, SmtpMailAccountsModule, MailTemplateLanguagesConfigService } from "@impacgroup/angular-mailtemplates";
import {
    FirebaseConfigModule,
    FirebaseDevicesModule,
    FirebaseNotificationsModule,
    FirebaseNotificationTemplatesModule,
    FirebaseNotificationLanguagesConfigService,
    FirebaseNotificationTemplateLanguagesConfigService
} from "@impacgroup/angular-firebase";
import { QuillModule } from "ngx-quill";
import { SystemLogMessagesModule, SystemJobPerformancesModule, SystemResourceSnapshotsModule, SystemErrorsModule } from "@impacgroup/angular-systemhealth";
import { StandardCountryService } from "./global/services/country.service";
import { PostcodeAreasModule } from "./modules/postcodeareas/postcodeareas.module";
import { FlyerImagesModule } from "./modules/flyerimages/flyerimages.module";
import { InterestedPartiesModule } from "./modules/interestedparties/interestedparties.module";
import { ChecklistTemplateModule } from "./modules/checklisttemplate/checklisttemplate.module";
import { DistributorsModule } from "./modules/distributors/distributors.module";
import { ChecklistsModule } from "./modules/checklists/checklists.module";
import { ShopItemsModule } from "./modules/shopitems/shopitems.module";
import { OrderItemsModule } from "./modules/orderitems/orderitems.module";
import { VisitReportsModule } from "./modules/visitreports/visitreports.module";
import * as moment from "moment";
import { ToursModule } from "./modules/tours/tours.module";

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    return () =>
        new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
            locationInitialized.then(() => {
                moment.locale("de");

                translate.addLangs(["de"]);

                const langToSet = "de";
                translate.setDefaultLang("de");
                translate.use(langToSet).subscribe(
                    () => {
                        // console.log(`Successfully initialized '${langToSet}' language.'`);
                    },
                    (err) => {
                        console.error(`Problem with '${langToSet}' language initialization.'`);
                    },
                    () => {
                        resolve(null);
                    }
                );
            });
        });
}

export const httpInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }];
@NgModule({
    declarations: [AppComponent, SidebarComponent, HeaderComponent, FooterComponent, FullLayoutComponent, SingleLayoutComponent, LogoComponent, NavigationTriggerComponent, UserComponent, PageLoaderComponent],
    imports: [
        AngularBaseModule.forRoot(environment.httpRequestTimeoutSeconds),
        OAuthModule.forRoot(
            {
                PERMISSIONS: PermissionsConfig.PERMISSIONS
            },
            environment.oauthBaseAPI
        ),
        OrganizationsModule.forRoot({
            organizationsAPI: environment.organizationsAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        RolesModule.forRoot({
            rolesAPI: environment.rolesAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        UsersModule.forRoot({
            usersAPI: environment.usersAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        ProfileModule.forRoot({
            profileAPI: environment.profileAPI
        }),
        ResetPasswordModule.forRoot({
            resetPasswordAPI: environment.resetPasswordAPI
        }),
        LanguagesModule.forRoot({
            languagesAPI: environment.languagesAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        SmtpMailAccountsModule.forRoot({
            smtpMailAccountsAPI: environment.smtpMailAccountsAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        MailTemplatesModule.forRoot({
            mailtemplatesAPI: environment.mailtemplatesAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        FirebaseConfigModule.forRoot({
            firebaseConfigAPI: environment.firebaseConfigAPI
        }),
        FirebaseDevicesModule.forRoot({
            firebaseDevicesAPI: environment.firebaseDevicesAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        FirebaseNotificationsModule.forRoot({
            firebaseNotificationsAPI: environment.firebaseNotificationsAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE,
            notificationDataProperties: ["url"]
        }),
        FirebaseNotificationTemplatesModule.forRoot({
            firebaseNotificationTemplatesAPI: environment.firebaseNotificationTemplatesAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        SystemLogMessagesModule.forRoot({
            systemLogMessagesAPI: environment.systemLogMessagesAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        SystemJobPerformancesModule.forRoot({
            systemJobPerformancesAPI: environment.systemJobPerformancesAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        SystemResourceSnapshotsModule.forRoot({
            systemResourceSnapshotsAPI: environment.systemResourceSnapshotsAPI,
            utcDateFormat: environment.utcDateFormat
        }),
        SystemErrorsModule.forRoot({
            systemErrorsAPI: environment.systemErrorsAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        PostcodeAreasModule.forRoot({
            postcodeAreasAPI: environment.postcodeAreasAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        FlyerImagesModule.forRoot({
            flyerImagesAPI: environment.flyerImagesAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        InterestedPartiesModule.forRoot({
            interestedPartiesAPI: environment.interestedPartiesAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        ChecklistTemplateModule.forRoot({
            checklistTemplateAPI: environment.checklistTemplateAPI,
            utcDateFormat: environment.utcDateFormat
        }),
        ToursModule.forRoot({
            toursAPI: environment.toursAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        DistributorsModule.forRoot({
            distributorsAPI: environment.distributorsAPI,
            dateFormat: environment.preferredDateFormat,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        ChecklistsModule.forRoot({
            checklistsAPI: environment.checklistsAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        OrderItemsModule.forRoot({
            orderItemsAPI: environment.orderItemsAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        ShopItemsModule.forRoot({
            shopItemsAPI: environment.shopItemsAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        VisitReportsModule.forRoot({
            visitReportsAPI: environment.visitReportsAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.GLOBAL_SESSION
        }),
        AlertModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        NgScrollbarModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        ButtonsModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        TranslateModule.forRoot(),
        NgSelectModule,
        HttpClientModule,
        QuillModule.forRoot(),
        BsDatepickerModule.forRoot()
    ],
    providers: [
        AppService,
        StandardCountryService,
        {
            provide: TranslateLoader,
            useFactory: translateLoaderService,
            deps: [HttpClient]
        },
        httpInterceptorProviders,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true
        },
        {
            provide: FirebaseNotificationLanguagesConfigService,
            useClass: LanguagesService
        },
        {
            provide: FirebaseNotificationTemplateLanguagesConfigService,
            useClass: LanguagesService
        },
        {
            provide: MailTemplateLanguagesConfigService,
            useClass: LanguagesService
        },
        HttpLoadingService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
