import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode } from "@impacgroup/angular-baselib";
import { FlyerImagesService } from "./flyerimages.service";
import { TranslateService } from "@ngx-translate/core";
import { FlyerImage } from "../../api-models/FlyerImage";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Component({
    selector: "app-flyerimagedetail",
    templateUrl: "./flyerimagedetail.component.html",
    styleUrls: ["flyerimagedetail.component.scss"]
})
export class FlyerImageDetailComponent extends BaseComponent implements OnInit {
    private flyerImageId: string = null;
    public flyerImage: FlyerImage = null;

    public selectedReturnFlyerImage: File = null;
    public returnFlyerImagePreviewImage: SafeUrl | string | ArrayBuffer = null;
    public returnFlyerImagePreviewImageStyle: SafeUrl = null;
    public showDropPlaces = false;

    constructor(
        private route: ActivatedRoute,
        private flyerImagesService: FlyerImagesService,
        private router: Router,
        private alertService: AlertService,
        private translateService: TranslateService,
        private _location: Location,
        private sanitization: DomSanitizer
    ) {
        super();

        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.flyerImageId = params.id;
                if (this.flyerImageId) {
                    this.initEditMode();
                } else {
                    this.initAddMode();
                }
            })
        );
    }

    ngOnInit(): void {}

    initAddMode() {
        this.MODE = BaseComponentMode.ADD;
        this.flyerImage = new FlyerImage();
    }

    initEditMode() {
        this.MODE = BaseComponentMode.EDIT;

        this.subscriptions.push(
            this.flyerImagesService.flyerImageDetail(this.flyerImageId).subscribe(
                (result) => {
                    this.flyerImage = result;

                    if (this.flyerImage.filename) {
                        this.subscriptions.push(
                            this.flyerImagesService.downloadFlyerImageFile(this.flyerImage._id).subscribe((selectedReturnFlyerImage) => {
                                const reader = new FileReader();

                                reader.readAsDataURL(selectedReturnFlyerImage);
                                reader.onload = (_event) => {
                                    const trustedUrl = this.sanitization.bypassSecurityTrustUrl(`${reader.result}`);
                                    this.returnFlyerImagePreviewImage = trustedUrl;
                                };
                            })
                        );
                    }
                },
                (err) => {
                    this.router.navigate(["../"], { relativeTo: this.route });
                }
            )
        );
    }

    onDrop(ev) {
        ev.preventDefault();
        this.showDropPlaces = false;
        this.chooseFile(ev.dataTransfer.files);
    }

    allowDrop(ev) {
        ev.preventDefault();
    }

    onFormDragEnter(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        this.showDropPlaces = true;
    }

    onFormDragLeave(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        if (!ev.target.classList.contains("card-body")) {
            return;
        }
        this.showDropPlaces = false;
    }

    chooseFile(files: FileList) {
        if (files.length === 0) {
            return;
        }

        const file: File = files[0];

        const mimeType = file.type;
        if (mimeType.match(/(jpg|jpeg|png)\/*/) == null) {
            this.alertService.addCustomErrorByI18nId("flyerimages.detail.add.upload.validation.upload_type_invalid");
            return;
        }

        this.flyerImage.filename = file.name;

        const reader = new FileReader();
        this.selectedReturnFlyerImage = file;

        reader.readAsDataURL(file);
        reader.onload = (_event) => {
            this.returnFlyerImagePreviewImage = reader.result;
        };
    }

    save() {
        if (this.MODE === BaseComponentMode.ADD) {
            this.subscriptions.push(
                this.flyerImagesService.addFlyerImage(this.flyerImage, this.selectedReturnFlyerImage).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("flyerimages.detail.add.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }

        if (this.MODE === BaseComponentMode.EDIT) {
            this.subscriptions.push(
                this.flyerImagesService.updateFlyerImage(this.flyerImage, this.selectedReturnFlyerImage).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("flyerimages.detail.edit.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }
    }

    back() {
        this._location.back();
    }
}
