<div class="content__inner">
    <header class="content__title">
        <h1>{{ "distributors.overview.list.title" | translate }}</h1>
        <small>{{ "distributors.overview.list.subtitle" | translate }}</small>
    </header>

    <div class="card" *appHasPermission="'CHECKLIST.LIST'">
        <div class="card-body">
            <h4 class="card-title">{{ "distributors.overview.list.card.title" | translate }}</h4>

            <div class="form-group">
                <label>{{ "distributors.overview.filter.display.title" | translate }}</label>
                <div class="checkbox">
                    <input type="checkbox" id="isActiveOnly" [checked]="isActiveOnly" (change)="isActiveOnly = !isActiveOnly; isInactiveOnly = false; onChangeFilter()" />
                    <label class="checkbox__label" for="isActiveOnly">{{ "distributors.overview.filter.display.isActiveOnly" | translate }}</label>
                </div>
                <div class="checkbox">
                    <input type="checkbox" id="isInactiveOnly" [checked]="isInactiveOnly" (change)="isInactiveOnly = !isInactiveOnly; isActiveOnly = false; onChangeFilter()" />
                    <label class="checkbox__label" for="isInactiveOnly">{{ "distributors.overview.filter.display.isInactiveOnly" | translate }}</label>
                </div>
                <div class="checkbox">
                    <input type="checkbox" id="isDisabledOnly" [checked]="isDisabledOnly" (change)="isDisabledOnly = !isDisabledOnly; onChangeFilter()" />
                    <label class="checkbox__label" for="isDisabledOnly">{{ "distributors.overview.filter.display.isDisabledOnly" | translate }}</label>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <div class="form-group">
                        <label>{{ "distributors.overview.filter.status.title" | translate }}</label>
                        <div class="select">
                            <select class="form-control" #statusSelect [(ngModel)]="isStatus" (change)="onStatusSelected()">
                                <option [value]="undefined">{{ "distributors.overview.filter.status.options.all" | translate }}</option>
                                <option *ngFor="let statusOption of statusOptions" [value]="statusOption">{{ "distributors.overview.filter.status.options." + statusOption | translate }}</option>
                            </select>
                            <i class="form-group__bar"></i>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label>{{ "distributors.overview.filter.lifecycleStatus.title" | translate }}</label>
                        <div class="select">
                            <select class="form-control" #lifecycleStatusSelect [(ngModel)]="selectedLifecycleStatusFilter" (change)="onChangeFilter()">
                                <option [value]="undefined">{{ "distributors.overview.filter.lifecycleStatus.options.all" | translate }}</option>
                                <option *ngFor="let lifecycleStatusFilterItem of lifecycleStatusFilterItems" [value]="lifecycleStatusFilterItem">{{ lifecycleStatusFilterItem }}</option>
                            </select>
                            <i class="form-group__bar"></i>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ "distributors.overview.filter.salesline.title" | translate }}</label>
                        <input class="form-control" placeholder="{{ 'distributors.overview.filter.salesline.title' | translate }}" type="text" [(ngModel)]="saleslineFilter" (keyup)="saleslineChanged()" /><i class="form-group__bar"></i>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <div class="form-group">
                        <label>{{ "distributors.overview.filter.postcode.from" | translate }}</label>
                        <input
                            class="form-control"
                            placeholder="{{ 'distributors.overview.filter.postcode.title' | translate }}"
                            type="text"
                            [(ngModel)]="fromPostCode"
                            (keyup)="postCodeChanged(fromPostCode)"
                            [pattern]="numbersOnlyPattern"
                        /><i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="form-group">
                        <label>{{ "distributors.overview.filter.postcode.to" | translate }}</label>
                        <input class="form-control" placeholder="{{ 'distributors.overview.filter.postcode.title' | translate }}" type="text" [(ngModel)]="toPostCode" (keyup)="postCodeChanged(toPostCode)" [pattern]="numbersOnlyPattern" /><i
                            class="form-group__bar"
                        ></i>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ "distributors.overview.filter.city.title" | translate }}</label>
                        <input class="form-control" placeholder="{{ 'distributors.overview.filter.city.title' | translate }}" type="text" [(ngModel)]="city" (keyup)="cityChanged()" /><i class="form-group__bar"></i>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <button type="button" (click)="selectAll()" class="btn btn-success btn--icon-text float-left ml-2 mb-2">{{ "distributors.buttons.selectAll" | translate }}</button>
                    <button type="button" (click)="deselectAll()" class="btn btn-success btn--icon-text float-left ml-2 mb-2">{{ "distributors.buttons.deselectAll" | translate }}</button>
                </div>
            </div>

            <table datatable class="table table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th id="selected"></th>
                        <th id="vo">{{ "distributors.props.vo" | translate }}</th>
                        <th id="name" style="white-space: nowrap">{{ "distributors.props.name" | translate }}</th>
                        <th id="status">{{ "distributors.props.status" | translate }}</th>
                        <th id="lifecycleStatus" style="max-width: 130px">{{ "distributors.props.lifecycleStatus" | translate }}</th>
                        <th id="salesline">{{ "distributors.props.salesline" | translate }}</th>
                        <th id="checklistLastCreated">{{ "distributors.props.checklistLastCreated" | translate }}</th>
                        <th id="checklistLastCreatedBy">{{ "distributors.props.checklistLastCreatedBy" | translate }}</th>

                        <th id="actions"></th>
                        <th id="disabled">{{ "distributors.props.disabled" | translate }}</th>
                    </tr>
                </thead>
                <tbody *ngIf="rows?.length != 0">
                    <tr *ngFor="let row of rows" [ngClass]="{ marked: row.marked }">
                        <td>
                            <input type="checkbox" id="selected_{{ row._id }}" [(ngModel)]="selectedRows[row._id]" (change)="onSelectionToggle()" />
                        </td>
                        <td class="vo">{{ row.vo }}</td>
                        <td class="name" style="white-space: nowrap">{{ row.name }}<br />{{ row.street }}<br />{{ row.postcode }} {{ row.city }}</td>
                        <td class="status">{{ row.status }}</td>
                        <td class="lifecycleStatus" style="max-width: 130px">{{ row.lifecycleStatus }}</td>
                        <td class="salesline">{{ row.salesline }}</td>
                        <td class="checklistLastCreated">{{ row.checklistLastCreated | date : UTCDATEFORMAT : "+0" }}</td>
                        <td class="checklistLastCreatedBy">{{ row.formatChecklistLastCreatedBy() }}</td>

                        <td class="text-right nowrap">
                            <i
                                (click)="showChecklists(row._id)"
                                class="zmdi zmdi-collection-text zmdi-hc-fw tableIcon"
                                *appHasPermission="'CHECKLIST.READ'"
                                [tooltip]="'distributors.buttontooltips.downloadPDF' | translate"
                                placement="bottom"
                            ></i>
                        </td>
                        <td class="text-right nowrap">
                            <span *appHasPermission="'DISTRIBUTORS.DISABLE'">
                                <i (click)="changeState(row)" class="zmdi zmdi-crop-square zmdi-hc-fw tableIcon" *ngIf="!row.disabled"></i>
                                <i (click)="changeState(row)" class="zmdi zmdi-minus-square zmdi-hc-fw tableIcon text-danger" *ngIf="row.disabled"></i>
                            </span>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="rows?.length == 0">
                    <tr>
                        <td colspan="6" class="no-data-available">{{ "global.datatable.empty" | translate }}</td>
                    </tr>
                </tbody>

                <tbody></tbody>
            </table>

            <div class="col-12 mt-5">
                <span>
                    <button type="button" (click)="showTourDistributionDialog()" class="btn btn-success btn--icon-text float-right ml-2 mb-2" [disabled]="selectedTotal === 0">
                        <i class="zmdi zmdi-check"></i> {{ "distributors.buttons.addSelectionToTour" | translate }} {{ "(" + selectedTotal + ")" }}
                    </button>
                </span>
            </div>
        </div>
    </div>
</div>
