import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { IApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { classToPlain, plainToClass } from "class-transformer";
import { ShopItem } from "../../api-models/ShopItem";

export interface IShopItemConfigService {
    shopItemsAPI: string;
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const ShopItemConfigService = new InjectionToken<IShopItemConfigService>("ShopItemConfig");

@Injectable()
export class ShopItemsService {
    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(ShopItemConfigService) private shopItemConfig: IShopItemConfigService, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.UTCDATEFORMAT = this.shopItemConfig.utcDateFormat;
        this.datatableStateSaveMode = this.shopItemConfig.datatableStateSaveMode;
    }

    public shopItems(params: IApiListQueryParameter): Observable<{ list: ShopItem[]; count: number; total: number }> {
        return this.http
            .get<any>(this.getShopItemsServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: params as any,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    let count = result.body.length;
                    let total = result.body.length;
                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count"), 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count"), 10);
                    } catch (e) {}

                    return {
                        list: plainToClass(ShopItem, result.body as any[]),
                        count: count,
                        total: total
                    };
                })
            );
    }

    public shopItemDetail(id: string): Observable<ShopItem> {
        return this.http
            .get<Object>(this.getShopItemsServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(ShopItem, result)));
    }

    public addShopItem(shopItem: ShopItem): Observable<ShopItem> {
        return this.http
            .post<Object>(this.getShopItemsServiceUrl(""), classToPlain(shopItem), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(ShopItem, result)));
    }

    public updateShopItem(shopItem: ShopItem): Observable<ShopItem> {
        return this.http
            .patch<Object>(this.getShopItemsServiceUrl(shopItem._id), classToPlain(shopItem), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(ShopItem, result)));
    }

    public deleteShopItem(id: string): Observable<ShopItem> {
        return this.http
            .delete<Object>(this.getShopItemsServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(ShopItem, result)));
    }

    private getShopItemsServiceUrl(postfix: string): string {
        return this.shopItemConfig.shopItemsAPI + postfix;
    }
}
