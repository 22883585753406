import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TooltipModule } from "ngx-bootstrap/tooltip";

import { VisitReportsComponent } from "./visitreports.component";
import { VisitReportsService, VisitReportsConfigService, IVisitReportsConfigService } from "./visitreports.service";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { VisitReportsMainComponent } from "./visitreports.main.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { VisitReportsListStateService } from "./visitreports.liststate.service";

@NgModule({
    declarations: [VisitReportsMainComponent, VisitReportsComponent],
    imports: [CommonModule, RouterModule, FormsModule, DataTablesModule, TooltipModule.forRoot(), BsDropdownModule, ModalModule, NgSelectModule, TranslateModule, OAuthModule, BsDatepickerModule.forRoot()],
    exports: [],
    providers: []
})
export class VisitReportsModule {
    static forRoot(visitReportsConfig: IVisitReportsConfigService): ModuleWithProviders<VisitReportsModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: VisitReportsModule,
            providers: [
                VisitReportsListStateService,
                VisitReportsService,
                {
                    provide: VisitReportsConfigService,
                    useValue: visitReportsConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: VisitReportsMainComponent,
                children: [
                    {
                        path: "",
                        component: VisitReportsComponent
                    }
                ]
            }
        ];
    }
}
